import React, { Component } from 'react';

export default class TaggerView extends Component {
  /**
   * Expects:
   * - this.props.content: string
   * - this.props.tags: array
   *
   * Each tag in this.props.tags contains
   * - start: int - offset of the first character
   * - end: int - offset of the last character
   * - badge: string - badge class to use for rendering
   */

  render() {
    var content = this.props.content;
    for (var index = this.props.tags.length - 1; index >= 0; --index) {
      const tag = this.props.tags[index];
      content
        = content.substring(0, tag.start)
        + "<span class='badge badge-" + tag.badge + "'>" + content.substring(tag.start, tag.end + 1) + "</span>"
        + content.substring(tag.end + 1);
    }

    var renderedContent = {__html: content};

    return (
      <>
        <div className="tagger" dangerouslySetInnerHTML={renderedContent}>
        </div>
      </>
    );
  }
}
