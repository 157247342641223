import * as graphlib from 'graphlib'

export default class GraphModel {

  constructor() {
    this.initialize();
  }

  initialize() {
    // Create object to store the graph
    this.graph = new graphlib.Graph({
      directed: false,
      compound: false,
      multigraph: true
    });

    // Start off without a diff
    this.mostRecentDiff = null;
  }

  incorporate(graphDiff) {
    // TODO: Current implementation never removes anything from the graph.
    // As the result there is no a way to cleanup preview other than wiping it entirely.

    // Add missing nodes
    for ( const diffGraphNodeId of graphDiff.nodes() ) {
      if ( this.graph.hasNode(diffGraphNodeId) ) {
        continue;
      }

      const diffGraphNode = graphDiff.node(diffGraphNodeId);
      this.graph.setNode(diffGraphNodeId, diffGraphNode);
    }

    // Add missing vertices
    for ( const diffGraphEdgeObj of graphDiff.edges() ) {
      if ( this.graph.hasEdge(diffGraphEdgeObj) ) {
        continue;
      }

      const diffGraphEdge = graphDiff.edge(diffGraphEdgeObj);
      this.graph.setEdge(diffGraphEdgeObj, diffGraphEdge);
    }

    // Remember the diff
    this.mostRecentDiff = graphDiff;
  }

  clear() {
    // Cleanup the graph by recreating its internal storage.
    this.initialize();
  }
}
