import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import CloseControl from './close';

export default class View extends Component {
  render() {
    let edge = this.props.shell.details.data;
    let edgePropertyNames = Object.keys(edge.properties);

    return (
      <Card>
        <Card.Header>
          Edge Details
          <span className="float-right">
            <CloseControl shell={this.props.shell} />
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Text as="h6">General</Card.Text>
          <Card.Text>
            <table className="properties">
              <tr>
                <td className="prop-name-column">ID</td>
                <td className="prop-value-column">{edge.id}</td>
              </tr>
              <tr>
                <td className="prop-name-column">Class</td>
                <td className="prop-value-column">{edge.class}</td>
              </tr>
            </table>
          </Card.Text>
          {edgePropertyNames.length > 0 &&
            <>
              <Card.Text as="h6">Properties</Card.Text>
              <Card.Text>
                <table className="properties">
                  {edgePropertyNames.map((propertyName, index) => {
                    return (
                      <tr>
                        <td className="prop-name-column">{propertyName}</td>
                        <td className="prop-value-column">{edge.properties[propertyName].toString()}</td>
                      </tr>
                    );
                  })}
                </table>
              </Card.Text>
            </>
          }
        </Card.Body>
      </Card>
    );
  }
}
