import React, { Component } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import Navigation from "context/nav";

export default class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifying: false,
      deleting: false,
      deletionError: null
    };
  }

  handleDelete = () => {
    this.setState({
      modifying: true,
      deleting: true,
      deletionError: null
    });

    // Delete blob
    const orgId = this.props.blob.org_id;
    const blobId = this.props.blob.id;
    this.props.app.api.blobsIdDelete(orgId, blobId)
      .then(response => {
        this.setState({
          modifying: false,
          deleting: false,
          deletionError: null
        });

        // Navigate to org page
        Navigation.push(this.props.history, Navigation.orgPath(this.props.org));
      }, error => {
        // Indicate error
        this.setState({
          modifying: false,
          deleting: false,
          deletionError: error.message
        });
      });
  }

  render() {
    return (
      <>
        <h5>Delete Blob</h5>
        <hr/>
        <div>
          Blob removal cannot be reversed or undone. Please double check that the blob you are about to remove
          is no longer used and its data is backed up.
        </div>
        {this.state.deleting &&
          <div className="content-row">
            <Alert variant="primary">
              Scheduling blob deletion...
            </Alert>
          </div>
        }
        {this.state.deletionError &&
          <div className="content-row">
            <Alert variant="warning">
              {this.state.deletionError}
            </Alert>
          </div>
        }
        {!this.state.modifying &&
          <div className="content-row">
            <Button
              type="submit"
              variant="success"
              size="sm"
              onClick={this.handleDelete}>
              Delete Blob
            </Button>
          </div>
        }
      </>
    );
  }
}
