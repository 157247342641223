import React, { Component } from 'react';
import { Container, Row, Col, Tabs, Tab, Card, Badge, Alert } from 'react-bootstrap';
import { GoRepo } from 'react-icons/go';
import ExternalLink from 'components/controls/ExternalLink/index';
import ReactGA from 'react-ga';
import './index.css';

export default class BlobHeader extends Component {
  shouldShowDescriptionLine() {
    return (
      ( this.props.blob.description && this.props.blob.description.length > 0 ) ||
      ( this.props.blob.url && this.props.blob.url.length > 0 ) ||
      ( this.props.blob.tags && this.props.blob.tags.length > 0 )
    );
  }

  render() {
    const orgId = this.props.match.params.orgId;

    return (
      <>
        <Container className="blob-header-row repo-name-row">
          <Row>
            <Col sm={6}>
              <h5>
                <GoRepo/>
                &nbsp;&nbsp;<a href={`/${orgId}`}>{this.props.org
                  ? ( this.props.org.name
                    ? <>{this.props.org.name}</>
                    : <>{this.props.org.alias}</> )
                  : <>{orgId}</>}
                </a> /&nbsp;
                <a href={`/${orgId}/${this.props.blob.alias}`}>{this.props.blob.name
                  ? <>{this.props.blob.name}</>
                  : <>{this.props.blob.alias}</>}
                </a>
              </h5>
            </Col>
            <Col sm={6}>
            </Col>
          </Row>
        </Container>
          <Container className="blob-header-row repo-description-row">
            {this.shouldShowDescriptionLine() &&
              <>
                {this.props.blob.description && this.props.blob.description.length > 0 &&
                  <>
                    {this.props.blob.description}&nbsp;
                  </>
                }
                {this.props.blob.url && this.props.blob.url.length > 0 &&
                  <>
                    <ExternalLink href={this.props.blob.url} text={this.props.blob.url}/>&nbsp;
                  </>
                }
                {this.props.blob.tags && this.props.blob.tags.length > 0 &&
                  <>
                    {this.props.blob.tags.map((tag, index) => {
                      return (
                        <>
                          <Badge variant="secondary" className="tag-badge">{tag}</Badge>
                          &nbsp;
                        </>
                      );
                    })}
                  </>
                }
              </>
            }
          </Container>
      </>
    );
  }
}
