import React, { Component } from 'react';
import { Card, Container, Button, Col, Row, Table, Alert, ListGroup, ListGroupItem, Form } from 'react-bootstrap';
import Octicon, { Plus, Pencil, X, Check, Trashcan } from '@github/octicons-react';
import "./appearance.css"

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-monokai";

class EditbleTemplateList extends Component {

  constructor(props) {
    super(props);
    this.headerLabel = "";
    this.defaultState = {
      newItem: null,
      editItem: null,
      deleteItem: null,
      progress: false,
      failedItemId: null,
      callError: null
    };
    this.state = this.defaultState;
  }

  initState(state) {
    let initial = JSON.parse(JSON.stringify(this.defaultState));
    let merged = Object.assign(initial, state);
    this.setState(merged);
  }

  templatesList() {
    return [];
  }

  handleCreateInit = () => {
    this.initState({
      newItem: {
        "type": this.templateType,
        "class": "",
        "label": {
          "template": ""
        }
      }
    });
  }

  handleCreateComplete = (event) => {
    event.preventDefault();

    // Check form validity
    const form = event.currentTarget;
    if ( form.checkValidity() === false ) {
      return;
    }

    this.setState({
      progress: true,
      callError: null,
      failedItemId: null
    });

    const revisionId = this.props.revision.id;
    const item = this.state.newItem;
    this.props.app.api.revisionsIdMetadataAliasPost(revisionId, "appearance", item)
      .then(response => {
        // Update internal state
        this.handleClearAll();

        // Reload the list
        this.props.shell.appearanceInterface.refreshAppearance();
      }, error => {
        this.initState({
          callError: "Failed to create template",
          failedItemId: null
        });
      });
  }

  handleEditInit = (item) => {
    this.initState({
      editItem: JSON.parse(JSON.stringify(item))
    });
  }

  handleEditComplete = (event) => {
    event.preventDefault();

    // Check form validity
    const form = event.currentTarget;
    if ( form.checkValidity() === false ) {
      return;
    }

    this.setState({
      progress: true,
      callError: null,
      failedItemId: null
    });

    const item = this.state.editItem;
    this.props.app.api.revisionsMetadataIdPut(item.id, item)
      .then(response => {
        // Update internal state
        this.handleClearAll();

        // Reload the list
        this.props.shell.appearanceInterface.refreshAppearance();
      }, error => {
        this.initState({
          callError: "Failed to update template",
          failedItemId: item.id
        });
      });
  }

  handleDeleteInit = (item) => {
    this.initState({
      deleteItem: item
    });
  }

  handleDeleteComplete = () => {
    this.setState({
      progress: true,
      callError: null,
      failedItemId: null
    });

    const item = this.state.deleteItem;
    this.props.app.api.revisionsMetadataIdDelete(item.id)
      .then(response => {
        // Update internal state
        this.handleClearAll();

        // Reload the list
        this.props.shell.appearanceInterface.refreshAppearance();
      }, error => {
        this.initState({
          callError: "Failed to delete template",
          failedItemId: item.id
        });
      });
  }

  handleClearAll = () => {
    this.initState({});
  }

  render() {
    return (
      <Card>
        <ListGroup className="list-group-flush">
          <ListGroupItem>
            <Card.Text as="h6">{this.headerLabel}</Card.Text>
          </ListGroupItem>
          <ListGroupItem>
            {this.templatesList().length > 0
              ?
                <Form noValidate validated={true} onSubmit={this.handleEditComplete}>
                  <table className="appearance-templates-table">
                    <tbody>
                      {this.templatesList().map((template, index) => {
                        return (
                          <>
                            {(this.state.editItem && template.id == this.state.editItem.id) &&
                              <>
                                {!this.state.progress &&
                                  <tr>
                                    <td className="template-class">
                                      <Form.Control
                                        required
                                        size="sm"
                                        type="text"
                                        placeholder="Class"
                                        value={this.state.editItem.class}
                                        onChange={(event) => {
                                          let item = this.state.editItem;
                                          item.class = event.target.value;
                                          this.setState({editItem: item});
                                        }}
                                      />
                                    </td>
                                    <td className="template-template-editor">
                                      <AceEditor
                                        name="template-editor"
                                        width="100%"
                                        height="100px"
                                        theme="monokai"
                                        showGutter={false}
                                        value={this.state.editItem.label.template}
                                        onChange={(value) => {
                                          let item = this.state.editItem;
                                          item.label.template = value;
                                          this.setState({editItem: item});
                                        }}
                                      />
                                      <Form.Group>
                                        <Form.Control
                                          required
                                          hidden
                                          type="text"
                                          value={this.state.editItem.label.template}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Template is required.
                                        </Form.Control.Feedback>
                                      </Form.Group>
                                    </td>
                                    <td className="template-actions">
                                      <Button
                                        variant="light"
                                        type="submit"><Octicon icon={Check} size="sm" />
                                      </Button>
                                      <Button
                                        variant="light"
                                        onClick={this.handleClearAll}><Octicon icon={X} size="sm" />
                                      </Button>
                                    </td>
                                  </tr>
                                }
                                {this.state.progress &&
                                  <tr>
                                    <td colSpan="3">
                                      <Alert variant="primary">
                                        Updating the template...
                                      </Alert>
                                    </td>
                                  </tr>
                                }
                              </>
                            }
                            {template == this.state.deleteItem &&
                              <>
                                {!this.state.progress &&
                                  <>
                                    <tr>
                                      <td className="template-class">
                                        <code>{template["class"]}</code>
                                      </td>
                                      <td className="template-template">
                                        <code>
                                          {template["template"]}
                                        </code>
                                      </td>
                                      <td className="template-actions"></td>
                                    </tr>
                                    <tr>
                                      <td></td>
                                      <td>
                                        Confirm deletion
                                      </td>
                                      <td className="template-actions">
                                        <Button
                                          variant="light"
                                          onClick={this.handleDeleteComplete}><Octicon icon={Check} size="sm" />
                                        </Button>
                                        <Button
                                          variant="light"
                                          onClick={this.handleClearAll}><Octicon icon={X} size="sm" />
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                }
                                {this.state.progress &&
                                  <tr>
                                    <td colSpan="3">
                                      <Alert variant="primary">
                                        Deleting the template...
                                      </Alert>
                                    </td>
                                  </tr>
                                }
                              </>
                            }
                            {((!this.state.editItem || template.id != this.state.editItem.id) && template != this.state.deleteItem) &&
                              <>
                                <tr>
                                  <td className="template-class">
                                    <code>{template["class"]}</code>
                                  </td>
                                  <td className="template-template">
                                    <code>
                                      {template["label"]["template"]}
                                    </code>
                                  </td>
                                  <td className="template-actions">
                                    {this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision) && !this.state.progress &&
                                      <>
                                        <Button
                                          variant="light"
                                          onClick={() => this.handleEditInit(template)}><Octicon icon={Pencil} size="sm" />
                                        </Button>
                                        <Button
                                          variant="light"
                                          onClick={() => this.handleDeleteInit(template)}><Octicon icon={Trashcan} size="sm" />
                                        </Button>
                                      </>
                                    }
                                  </td>
                                </tr>
                                {(template.id == this.state.failedItemId && this.state.callError) &&
                                  <tr>
                                    <td colSpan="3">
                                      <Alert variant="warning">
                                        {this.state.callError}
                                      </Alert>
                                    </td>
                                  </tr>
                                }
                              </>
                            }
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </Form>
              :
                <div>
                  Add representation templates to customize appearance of the graph.
                </div>
            }
          </ListGroupItem>
          {this.state.newItem &&
            <>
              {!this.state.progress &&
                <ListGroupItem>
                  <Form noValidate validated={true} onSubmit={this.handleCreateComplete}>
                    <table className="appearance-templates-table">
                      <tbody>
                        <tr>
                          <td className="template-class">
                            <Form.Group>
                              <Form.Control
                                required
                                size="sm"
                                type="text"
                                placeholder="Class"
                                value={this.state.newItem.class}
                                onChange={(event) => {
                                  let item = this.state.newItem;
                                  item.class = event.target.value;
                                  this.setState({newItem: item});
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Class is required.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td className="template-template-editor">
                            <AceEditor
                              name="template-editor"
                              width="100%"
                              height="100px"
                              theme="monokai"
                              value={this.state.newItem.label.template}
                              onChange={(value) => {
                                let item = this.state.newItem;
                                item.label.template = value;
                                this.setState({newItem: item});
                              }}
                            />
                            <Form.Group>
                              <Form.Control
                                required
                                hidden
                                type="text"
                                value={this.state.newItem.label.template}
                              />
                              <Form.Control.Feedback type="invalid">
                                Template is required.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </td>
                          <td className="template-actions">
                            <Button
                              type="submit"
                              variant="light"><Octicon icon={Check} size="sm" />
                            </Button>
                            <Button
                              variant="light"
                              onClick={this.handleClearAll}><Octicon icon={X} size="sm" />
                            </Button>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">
                            Use <code>${"{"}property.name{"}"}</code> syntax to insert variables into a template.
                            Vertex and edge properties are available
                            under <code>vertex</code> and <code>edge</code> objects respectively.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Form>
                </ListGroupItem>
              }
              {this.state.progress &&
                <Alert variant="primary">
                  Creating new template...
                </Alert>
              }
            </>
          }
          {(this.state.callError && !this.state.failedItemId) &&
            <Alert variant="warning">
              {this.state.callError}
            </Alert>
          }
          {this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision) && !this.state.progress &&
            <ListGroupItem>
              <Button
                variant="success" size="sm"
                onClick={this.handleCreateInit}><Octicon icon={Plus}/>
              </Button>
            </ListGroupItem>
          }
        </ListGroup>
      </Card>
    );
  }
}

class VertexTemplateList extends EditbleTemplateList {

  constructor(props) {
    super(props);
    this.headerLabel = "Vertices";
    this.templateType = "vertex";
  }

  templatesList() {
    return this.props.shell.appearance.vertices;
  }

}

class EdgeTemplateList extends EditbleTemplateList {

  constructor(props) {
    super(props);
    this.headerLabel = "Edges";
    this.templateType = "edge";
  }

  templatesList() {
    return this.props.shell.appearance.edges;
  }

}

export default class AppearanceView extends Component {
  constructor(props) {
    super(props);

    this.props.shell.appearanceInterface = {
      refreshAppearance: this.refreshAppearance
    };

    this.loadAppearance();
  }

  /**
   * Appearance interface
   */

  refreshAppearance = () => {
    // Reload the entire appearance metadata
    this.loadAppearance();
  }

  /**
   * Appearance sync section
   */

  loadAppearance() {
    const revisionId = this.props.revision.id;
    this.props.app.api.revisionsIdMetadataAliasGet(revisionId, "appearance")
      .then(response => {
        this.parseAppearanceTemplates(response.data.items);
      }, error => {
        // Leaving in un-initialized state for now
      });
  }

  parseAppearanceTemplates(appearanceItems) {
    var appearance = {
      vertices: [],
      verticesIndexByClass: {},
      edges: [],
      edgesIndexByClass: {}
    };
    for ( var appearanceItem of appearanceItems ) {
      if ( "vertex" == appearanceItem["type"] ) {
        appearance.vertices.push(appearanceItem);
        appearance.verticesIndexByClass[appearanceItem["class"]] = appearanceItem;
      } else if ( "edge" == appearanceItem["type"] ) {
        appearance.edges.push(appearanceItem);
        appearance.edgesIndexByClass[appearanceItem["class"]] = appearanceItem;
      }
    }

    this.props.shell.shellInterface.setAppearance(appearance);
  }

  /**
   * UX
   */

  render() {
    return (
      <>
        {this.props.shell.appearance &&
          <>
            <Row className="appearance-container">
              <Col>
                <VertexTemplateList
                  app={this.props.app}
                  blob={this.props.blob}
                  revision={this.props.revision}
                  shell={this.props.shell} />
              </Col>
            </Row>
            <Row className="appearance-container">
              <Col>
                <EdgeTemplateList
                  app={this.props.app}
                  blob={this.props.blob}
                  revision={this.props.revision}
                  shell={this.props.shell} />
              </Col>
            </Row>
          </>
        }
        {!this.props.shell.appearance &&
          <div>
            <Alert variant="primary">
              Loading vertex and edge appearance templates...
            </Alert>
          </div>
        }
      </>
    );
  }
}
