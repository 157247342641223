import React, { Component } from 'react';
import { Container, Button, Alert, Table, Badge, Form, Modal, Row, Col } from 'react-bootstrap';
import { GoKey, GoTrashcan } from "react-icons/go";
import Select from "react-select";

class ApiKeyConfirmModal extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if ( !this.props.apiKey ) {
      return <></>;
    }
    return (
      <Modal
        onHide={this.props.onHide}
        show={this.props.show}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            API Key Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>
                <strong>Key</strong>
              </Form.Label>
              <Row>
                <Col>
                  <code style={{overflowWrap: "break-word"}}>
                    {this.props.apiKey.key}
                  </code>
                </Col>
              </Row>
              <Form.Text muted>
                Make sure to copy the key and save it in secure location.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class AddApiKeyModal extends Component {
  constructor(props) {
    super(props);

    this.roleOptions = [
      { value: 'read', label: 'Read' },
      { value: 'write', label: 'Write' }
    ];

    this.state = {
      apiKey: {
        role: this.roleOptions[0],
        description: ""
      }
    };

    this.form = React.createRef();
  }

  onEnter = () => {
    this.setState({
      apiKey: {
        role: this.roleOptions[0],
        description: ""
      }
    });
  }

  handleAddApiKey = () => {
    // Check form validity
    if ( !this.form.current.reportValidity() ) {
      return;
    }

    // Initiate processing
    const apiKey = {
      role: this.state.apiKey.role.value,
      description: this.state.apiKey.description
    };
    this.props.handleAddApiKey(apiKey);

    // Close modal
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        onEnter={this.onEnter}
        onHide={this.props.onHide}
        show={this.props.show}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add New API Key
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={this.form}>
            <Form.Group>
              <Form.Label>
                <strong>Role</strong> <span className="text-muted">(required)</span>
              </Form.Label>
              <Row>
                <Col sm={4}>
                  <Select
                    options={this.roleOptions}
                    value={this.state.apiKey.role}
                    onChange={(option) => {
                      let apiKey = this.state.apiKey;
                      apiKey.role = option;
                      this.setState({apiKey: apiKey});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Selecting the right role is critical to make sure that blob data is properly protected.
              </Form.Text>
              <Form.Text muted>
                The following roles are supported:
              </Form.Text>
              <Form.Text muted>
                <ul>
                  <li><strong>Read</strong> Provides readonly access to blob, its metadata, and revisions.</li>
                  <li><strong>Write</strong> Allows contributing to blob metadata, and enables revision management.</li>
                </ul>
              </Form.Text>
            </Form.Group>
            <hr/>

            <Form.Group>
              <Form.Label>
                <strong>Description</strong>
              </Form.Label>
              <Row>
                <Col sm={9}>
                  <Form.Control
                    type="text"
                    size="sm"
                    autoFocus
                    value={this.state.apiKey.description}
                    onChange={(event) => {
                      let apiKey = this.state.apiKey;
                      apiKey.description = event.target.value;
                      this.setState({apiKey: apiKey});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Provide optional description to be able to differentiate API keys belonging to the same blob.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>Close</Button>
          <Button variant="success" onClick={this.handleAddApiKey}>Add</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class BlobApiKey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      deletingError: null
    };
  }

  handleDelete() {
    this.setState({
      deleting: true,
      deletingError: null
    });
    this.props.apiKeysInterface.setModifying(true);

    const apiKeyId = this.props.apiKey.id;
    this.props.app.api.blobApiKeysIdDelete(apiKeyId)
      .then(response => {
        this.setState({deleting: false});
        this.props.apiKeysInterface.setModifying(false);
        this.props.apiKeysInterface.loadApiKeys();
      }, error => {
        this.setState({
          deleting: false,
          deletingError: error.message
        });
        this.props.apiKeysInterface.setModifying(false);
      });
  }

  render() {
    return (
      <>
        <tr>
          <td>
            <div className="d-flex">
              <div>
                <GoKey/>
                {this.props.apiKey.description &&
                  <strong>
                    &nbsp;{this.props.apiKey.description}
                  </strong>
                }
                &nbsp;
                {this.props.apiKey.description && <>(</>}
                <code>
                  {this.props.apiKey.key_prefix}
                </code>
                {this.props.apiKey.description && <>)</>}
                &nbsp;&nbsp;
                <Badge variant="secondary" className="title-tag-badge">{this.props.apiKey.role}</Badge>
              </div>

              <div className="ml-auto">
                {!this.props.apiKeysInterface.modifying &&
                  <Button
                    variant="danger"
                    className="btn-icon"
                    onClick={() => this.handleDelete()}
                    ><GoTrashcan /></Button>
                }
              </div>
            </div>
          </td>
        </tr>
        {this.state.deleting &&
          <tr>
            <td style={{padding: "0px"}}>
              <div>
                <Alert variant="primary">
                  Deleting API key...
                </Alert>
              </div>
            </td>
          </tr>
        }
        {this.state.deletingError &&
          <tr>
            <td style={{padding: "0px"}}>
              <div>
                <Alert variant="warning">
                  {this.state.deletingError}
                </Alert>
              </div>
            </td>
          </tr>
        }
      </>
    );
  }
}

export default class ApiKeys extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifying: false,
      creatingApiKey: false,
      creatingApiKeyError: null,
      addApiKeyModalShow: false,
      apiKeys: null,
      apiKey: null,
      setModifying: this.setModifying,
      loadApiKeys: this.loadApiKeys,
      addApiKeyModalShow: false,
      setAddApiKeyModalShow: (show) => {
        this.setState({addApiKeyModalShow: show});
      },
      apiKeyConfirmModalShow: false,
      setApiKeyConfirmModalShow: (show) => {
        this.setState({apiKeyConfirmModalShow: show});
      }
    };

    this.loadApiKeys();
  }

  setModifying = (modifying) => {
    this.setState({modifying: modifying});
  }

  loadApiKeys = () => {
    const orgId = this.props.blob.org_id;
    const blobId = this.props.blob.id;
    this.props.app.api.blobsIdApiKeysGet(orgId, blobId)
      .then(response => {
        this.setState({
          apiKeys: response.data.api_keys
        });
      }, error => {});
  }

  handleAddApiKeyInit() {
    this.setState({
      modifying: false,
      creatingApiKey: false,
      creatingApiKeyError: null,
      addApiKeyModalShow: true
    });
  }

  handleAddApiKey = (apiKey) => {
    this.setState({
      modifying: true,
      creatingApiKey: true,
      creatingApiKeyError: null
    });

    // Create API key
    const orgId = this.props.blob.org_id;
    const blobId = this.props.blob.id;
    this.props.app.api.blobsIdApiKeysPost(orgId, blobId, apiKey)
      .then(response => {
        this.setState({
          modifying: false,
          creatingApiKey: false,
          creatingApiKeyError: null,
          apiKey: response.data.api_key,
          apiKeyConfirmModalShow: true
        });

        // Refresh the list of API keys
        this.loadApiKeys();
      }, error => {
        // Indicate error
        this.setState({
          modifying: false,
          creatingApiKey: false,
          creatingApiKeyError: error.message
        });
      });
  }

  render() {
    return (
      <>
        <h5>API Keys</h5>
        <hr/>

        <div>
          API keys are used to provide unauthenticated actors access to blobs.
        </div>

        {this.state.apiKeys && 0 != this.state.apiKeys.length &&
          <div className="content-row">
            <Table hover bordered style={{"margin-bottom": "0px"}}>
              <tbody>
                {this.state.apiKeys.map((apiKey, index) => {
                  return (
                    <BlobApiKey
                      app={this.props.app}
                      apiKeysInterface={this.state}
                      apiKey={apiKey}
                    />
                  );
                })}
              </tbody>
            </Table>
          </div>
        }

        {!this.state.modifying &&
          <div className="content-row">
            <Button
              variant="success"
              size="sm"
              onClick={() => this.handleAddApiKeyInit()}><GoKey/> New
            </Button>

            <span className="text-muted text-note">
              &nbsp;&nbsp;&nbsp;Create key to make blob available via API
            </span>
          </div>
        }

        {this.state.creatingApiKey &&
          <div className="content-row">
            <Alert variant="primary">
              Creating blob API key...
            </Alert>
          </div>
        }
        {this.state.creatingApiKeyError &&
          <div className="content-row">
            <Alert variant="warning">
              {this.state.creatingApiKeyError}
            </Alert>
          </div>
        }

        <AddApiKeyModal
          show={this.state.addApiKeyModalShow}
          onHide={() => this.state.setAddApiKeyModalShow(false)}
          handleAddApiKey={this.handleAddApiKey}
        />

        <ApiKeyConfirmModal
          show={this.state.apiKeyConfirmModalShow}
          onHide={() => this.state.setApiKeyConfirmModalShow(false)}
          apiKey={this.state.apiKey}
        />
      </>
    );
  }
}
