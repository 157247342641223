import React, { Component } from 'react';
import { Container, Form, Alert, Table, Button, Badge, Row, Col } from 'react-bootstrap';
import { GoRepo, GoLock, GoTrashcan, GoPerson } from "react-icons/go";
import Select from "react-select";

export default class Access extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifying: false,
      updatingVisibility: false,
      updatingVisibilityError: null,
    };
  }

  handleUpdateVisibility = (visibility) => {
    this.setState({
      modifying: true,
      updatingVisibility: true,
      updatingVisibilityError: null
    });

    // Update org
    const orgId = this.props.org.id;
    const org = {
      "visibility": visibility
    };
    this.props.app.api.orgsIdPatch(orgId, org)
      .then(response => {
        this.setState({
          modifying: false,
          updatingVisibility: false,
          updatingVisibilityError: null
        });

        // Trigger org refresh upon visibility change
        this.props.profileInterface.loadProfile();
      }, error => {
        // Indicate error
        this.setState({
          modifying: false,
          updatingVisibility: false,
          updatingVisibilityError: error.message
        });
      });
  }

  render() {
    return (
      <>
        <h5>Visibility</h5>
        <hr/>
        <Form>
          <Form.Group>
            <Form.Check
              type="radio"
              name="visibility"
              id="visibility-public"
              label={<><GoRepo/> Public</>}
              disabled={this.state.updating}
              checked={"public" == this.props.org.visibility}
              onChange={(event) => {
                const visibility = event.target.checked ? "public" : "private";
                this.handleUpdateVisibility(visibility);
              }}
            />
            <Form.Text muted>
              <string>Public</string> organizations are visible to the world but only chosen users can contribute to it.
            </Form.Text>
            <Form.Check
              type="radio"
              name="visibility"
              id="visibility-private"
              label={<><GoLock/> Private</>}
              disabled={this.state.updating}
              checked={"private" == this.props.org.visibility}
              onChange={(event) => {
                const visibility = event.target.checked ? "private" : "public";
                this.handleUpdateVisibility(visibility);
              }}
            />
            <Form.Text muted>
              <string>Private</string> organizations are invisible by default with both producers and consumers being added manually.
            </Form.Text>

            {this.state.updatingVisibility &&
              <div className="content-row">
                <Alert variant="primary">
                  Changing organization visibility...
                </Alert>
              </div>
            }
            {this.state.updatingVisibilityError &&
              <div className="content-row">
                <Alert variant="warning">
                  {this.state.updatingVisibilityError}
                </Alert>
              </div>
            }
          </Form.Group>
        </Form>
      </>
    );
  }
}
