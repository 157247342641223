import React, { Component } from 'react';
import { Card, ButtonGroup, Button } from 'react-bootstrap';
import Octicon, {Info, GitCompare, ArrowLeft, ArrowRight} from '@github/octicons-react';
import CloseControl from './close';

export default class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: "navigation"
    };
  }

  handleNavigationView = () => {
    this.setState({ view: "navigation" });

  }

  handleInfoView = () => {
    this.setState({ view: "info" });
  }

  handleTraverseIn(edges) {
    let interpreter = this.props.shell.queryInterface.getInterpreter();
    let vertex = this.props.shell.details.data;
    let query = interpreter.queryTraverseIn(vertex, edges.class);
    this.props.shell.queryInterface.runQuery(query);
  }

  handleTraverseOut(edges) {
    let interpreter = this.props.shell.queryInterface.getInterpreter();
    let vertex = this.props.shell.details.data;
    let query = interpreter.queryTraverseOut(vertex, edges.class);
    this.props.shell.queryInterface.runQuery(query);
  }

  render() {
    let vertex = this.props.shell.details.data;
    let vertexPropertyNames = Object.keys(vertex.properties);

    return (
      <Card>
        <Card.Header>
          Vertex Details
          <span className="float-right">
              <ButtonGroup size="sm" className="header-options">
                <Button variant="light" onClick={this.handleNavigationView}><Octicon icon={GitCompare}/></Button>
                <Button variant="light" onClick={this.handleInfoView}><Octicon icon={Info}/></Button>
              </ButtonGroup>
              &nbsp;
              &nbsp;
            <CloseControl shell={this.props.shell} />
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Text as="h6">General</Card.Text>
          <Card.Text>
            <table className="properties">
              <tr>
                <td className="prop-name-column">ID</td>
                <td className="prop-value-column">{vertex.id}</td>
              </tr>
              <tr>
                <td className="prop-name-column">Class</td>
                <td className="prop-value-column">{vertex.class}</td>
              </tr>
            </table>
          </Card.Text>
          {"navigation" == this.state.view &&
            <>
              {vertex.edges.incoming.length > 0 &&
                <>
                  <Card.Text as="h6">Incoming</Card.Text>
                  <Card.Text>
                    <table className="properties">
                      {vertex.edges.incoming.map((edges, index) => {
                        return (
                          <tr>
                            <td className="prop-name-column">{edges.class}</td>
                            <td className="prop-value-column">{edges.count}</td>
                            <td className="vertex-edge-actions-column">
                              <Button
                                variant="light"
                                onClick={() => this.handleTraverseIn(edges)}><Octicon icon={ArrowLeft} size="sm" />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </Card.Text>
                </>
              }
              {vertex.edges.outgoing.length > 0 &&
                <>
                  <Card.Text as="h6">Outgoing</Card.Text>
                  <Card.Text>
                    <table className="properties">
                      {vertex.edges.outgoing.map((edges, index) => {
                        return (
                          <tr>
                            <td className="prop-name-column">{edges.class}</td>
                            <td className="prop-value-column">{edges.count}</td>
                            <td className="vertex-edge-actions-column">
                              <Button
                                variant="light"
                                onClick={() => this.handleTraverseOut(edges)}><Octicon icon={ArrowRight} size="sm" />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </Card.Text>
                </>
              }
            </>
          }
          {"info" == this.state.view && vertexPropertyNames.length > 0 &&
            <>
              <Card.Text as="h6">Properties</Card.Text>
              <Card.Text>
                <table className="properties">
                  {vertexPropertyNames.map((propertyName, index) => {
                    return (
                      <tr>
                        <td className="prop-name-column">{propertyName}</td>
                        <td className="prop-value-column">{vertex.properties[propertyName].toString()}</td>
                      </tr>
                    );
                  })}
                </table>
              </Card.Text>
            </>
          }
        </Card.Body>
      </Card>
    );
  }
}
