import React, { Component } from 'react';
import { Card, Breadcrumb, ButtonGroup, Button } from 'react-bootstrap';
import { GoInfo, GoX, GoListUnordered } from "react-icons/go";
import Utils from "context/utils";
import './index.css';

export default class GraphDetails extends Component {
  /**
   * Formatting Helpers
   */

  formatTensorType(type) {
    switch (type) {
      case 1: return "float32";
      case 2: return "uint8";
      case 3: return "int8";
      case 4: return "uint16";
      case 5: return "int16";
      case 6: return "int32";
      case 7: return "int64";
      case 8: return "string";
      case 9: return "bool";
      case 10: return "float16";
      case 11: return "double";
      case 12: return "uint32";
      case 13: return "unit64";
      case 14: return "complex64";
      case 15: return "complex128";
      case 16: return "bfloat16";
    }
  }

  formatAttrType(type) {
    switch (type) {
      case 1: return "float32";
      case 2: return "int64";
      case 3: return "string";
      case 4: return "tensor";
      case 5: return "graph";
      case 6: return "float32[]";
      case 7: return "int64[]";
      case 8: return "string[]";
      case 9: return "tensor[]";
      case 10: return "graph[]";
    }
  }

  formatShape(shape) {
    var shape_str = "";
    var separator = "[";
    for ( var dim of shape.dim ) {
      var dim_value = ( "" != dim.dim_param ? dim.dim_param : dim.dim_value );
      shape_str += separator + dim_value;
      separator = ",";
    }
    shape_str += "]";
    return shape_str;
  }

  byteToMB(val) {
    return (val/(1024*1024)).toFixed(2);
  }

  numberWithCommas(val) {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /**
   * UI Handlers
   */

  handleComponents = () => {
    this.props.shell.shellInterface.updateDetails(true, "components");
  }

  handleInfo = () => {
    this.props.shell.shellInterface.updateDetails(true, "info");
  }

  handleClose = () => {
    this.props.shell.shellInterface.updateDetails(false, "info");
  }

  /**
   * Rendering Section
   */

  render() {
    var title = "";
    var content = (<></>);
    if ( "components" == this.props.shell.details.view ) {
      title = "Components";
      content = this.renderComponents();
    } else if ( "info" == this.props.shell.details.view ) {
      if ( "graph" == this.props.shell.selection.type ) {
        // No selection -> show graph details
        title = "Graph Details";
        content = this.renderGraphDetails();
      } else {
        if ( "node" == this.props.shell.selection.type ) {
          // Graph node selected
          title = "Operator Details";
          content = this.renderNode();
        }
      }
    }

    return (
      <>
        <Card>
          <Card.Header>
            {title}

            <span className="float-right">
              <ButtonGroup size="sm" className="header-options">
                <Button variant="light" onClick={this.handleComponents}><GoListUnordered/></Button>
                {this.props.shell.graph &&
                  <Button variant="light" onClick={this.handleInfo}><GoInfo/></Button>
                }
              </ButtonGroup>
              &nbsp;
              &nbsp;
              <ButtonGroup size="sm" className="header-options">
                <Button variant="light" onClick={this.handleClose}><GoX/></Button>
              </ButtonGroup>
            </span>
          </Card.Header>
          {content}
        </Card>
      </>
    );
  }

  renderComponents() {
    return (
      <Card.Body>
        {this.props.shell.components.map((component, index) => {
          return (
            <>
              <Card.Text as="h6">{component.path}</Card.Text>
              <Card.Text>
                <table className="properties">
                  <tr>
                    <td className="prop-name-column">Path</td>
                    <td className="prop-value-column">{component.path}</td>
                  </tr>
                  <tr>
                    <td className="prop-name-column">Size</td>
                    <td className="prop-value-column">{Utils.formatBytes(component.size)}</td>
                  </tr>
                </table>
              </Card.Text>
            </>
          );
        })}
      </Card.Body>
    );
  }

  renderGraphDetails() {
    var initsIndex = {};
    for ( var init of this.props.shell.graph.graph.initializer ) {
      initsIndex[init.name] = init;
    };

    var inputs = [];
    for ( var input of this.props.shell.graph.graph.input ) {
      if ( initsIndex[input.name] ) {
        continue;
      }
      inputs.push((
        <>
          <tr>
            <td className="prop-name-column">{input.name}</td>
            <td className="prop-value-column">
              type: <code>{this.formatTensorType(input.type.tensor_type.elem_type)}</code>
            </td>
          </tr>
          <tr>
            <td className="prop-name-column"></td>
            <td className="prop-value-column">
              shape: <code>{this.formatShape(input.type.tensor_type.shape)}</code>
            </td>
          </tr>
        </>
      ));
    }

    var outputs = [];
    for ( var output of this.props.shell.graph.graph.output ) {
      outputs.push((
        <>
          <tr>
            <td className="prop-name-column">{output.name}</td>
            <td className="prop-value-column">
              type: <code>{this.formatTensorType(output.type.tensor_type.elem_type)}</code>
            </td>
          </tr>
          <tr>
            <td className="prop-name-column"></td>
            <td className="prop-value-column">
              shape: <code>{this.formatShape(output.type.tensor_type.shape)}</code>
            </td>
          </tr>
        </>
      ));
    }

    return (
      <>
        <Card.Body>
          <Card.Text as="h6">General</Card.Text>
          <Card.Text>
            <table className="properties">
              <tr>
                <td className="prop-name-column">Domain</td>
                <td className="prop-value-column">{this.props.blob.domain}</td>
              </tr>
              <tr>
                <td className="prop-name-column">Type</td>
                <td className="prop-value-column">{this.props.blob.type}</td>
              </tr>
              <tr>
                <td className="prop-name-column">Format</td>
                <td className="prop-value-column">{this.props.blob.format}</td>
              </tr>
            </table>
          </Card.Text>

          <Card.Text as="h6">ONNX</Card.Text>
          <Card.Text>
            <table className="properties">
              <tr>
                <td className="prop-name-column">Version</td>
                <td className="prop-value-column">{this.props.shell.graph.ir_version}</td>
              </tr>
              <tr>
                <td className="prop-name-column">Producer name</td>
                <td className="prop-value-column">{this.props.shell.graph.producer_name}</td>
              </tr>
              <tr>
                <td className="prop-name-column">Producer version</td>
                <td className="prop-value-column">{this.props.shell.graph.producer_version}</td>
              </tr>
              <tr>
                <td className="prop-name-column">Domain</td>
                <td className="prop-value-column">{this.props.shell.graph.domain}</td>
              </tr>
            </table>
          </Card.Text>

          {inputs.length > 0 &&
            <>
              <Card.Text as="h6">Inputs</Card.Text>
              <Card.Text>
                <table className="properties">
                  {inputs}
                </table>
              </Card.Text>
            </>
          }

          {outputs.length > 0 &&
            <>
              <Card.Text as="h6">Outputs</Card.Text>
              <Card.Text>
                <table className="properties">
                  {outputs}
                </table>
              </Card.Text>
            </>
          }
        </Card.Body>
      </>
    );
  }

  renderNode() {
    var vertex = this.props.shell.selection.data;

    var valuesIndex = {};
    for ( var init of this.props.shell.graph.graph.initializer ) {
      valuesIndex[init.name] = init;
    };
    for ( var input of this.props.shell.graph.graph.input ) {
      valuesIndex[input.name] = input;
    };
    for ( var output of this.props.shell.graph.graph.output ) {
      valuesIndex[output.name] = output;
    };
    for ( var value of this.props.shell.graph.graph.value_info ) {
      valuesIndex[value.name] = value;
    };

    var attributes = [];
    for ( var attribute of vertex.attribute ) {
      attributes.push(
        <>
          <tr>
            <td className="prop-name-column">{attribute.name}</td>
            <td className="prop-value-column">
              type: <code>{this.formatAttrType(attribute.type)}</code>
            </td>
          </tr>
        </>
      );
    }

    var inputs = [];
    if ( vertex.input ) {
      for (var name of vertex.input) {
        var input = valuesIndex[name];
        if ( !input ) {
          continue;
        }
        inputs.push(
          <>
            <tr>
              <td className="prop-name-column">{input.name}</td>
              <td className="prop-value-column">
                type: <code>{this.formatTensorType(input.type.tensor_type.elem_type)}</code>
              </td>
            </tr>
            <tr>
              <td className="prop-name-column"></td>
              <td className="prop-value-column">
                shape: <code>{this.formatShape(input.type.tensor_type.shape)}</code>
              </td>
            </tr>
          </>
        );
      }
    }

    var outputs = [];
    if ( vertex.output ) {
      for (var name of vertex.output) {
        var output = valuesIndex[name];
        if ( !output ) {
          continue;
        }
        outputs.push(
          <>
            <tr>
              <td className="prop-name-column">{output.name}</td>
              <td className="prop-value-column">
                type: <code>{this.formatTensorType(output.type.tensor_type.elem_type)}</code>
              </td>
            </tr>
            <tr>
              <td className="prop-name-column"></td>
              <td className="prop-value-column">
                shape: <code>{this.formatShape(output.type.tensor_type.shape)}</code>
              </td>
            </tr>
          </>
        );
      }
    }

    return (
      <>
        <Card.Body>
          <Card.Text as="h6">General</Card.Text>
          <Card.Text>
            <table className="properties">
              <tr>
                <td className="prop-name-column">type</td>
                <td className="prop-value-column">{vertex.op_type}</td>
              </tr>
              <tr>
                <td className="prop-name-column">name</td>
                <td className="prop-value-column">{vertex.name}</td>
              </tr>
            </table>
          </Card.Text>

          {attributes.length > 0 &&
            <>
              <Card.Text as="h6">Attributes</Card.Text>
              <Card.Text>
                <table className="properties">
                  {attributes}
                </table>
              </Card.Text>
            </>
          }

          {inputs.length > 0 &&
            <>
              <Card.Text as="h6">Inputs</Card.Text>
              <Card.Text>
                <table className="properties">
                  {inputs}
                </table>
              </Card.Text>
            </>
          }

          {outputs.length > 0 &&
            <>
              <Card.Text as="h6">Outputs</Card.Text>
              <Card.Text>
                <table className="properties">
                  {outputs}
                </table>
              </Card.Text>
            </>
          }

        </Card.Body>
      </>
    );

  }
}
