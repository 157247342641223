import React, { Component } from 'react';
import { Container, Badge, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { GoRepo, GoGear } from "react-icons/go";
import Navigation from "context/nav";
import "./blobs.css";

export default class Page extends Component {
  render() {
    const orgId = this.props.orgId;
    const org = this.props.org;
    const blobs = this.props.blobs;
    return (
      <>
        <Container className="blob-header-row content-row blob-list-row">
          {org.is_self &&
            <>
              <div>
                &nbsp;
                <div className="float-right">
                  <span className="text-muted text-note">
                    Create a new blob to store data
                  </span>&nbsp;&nbsp;&nbsp;
                  <Button
                    variant="success"
                    size="sm"
                    href={Navigation.blobCreatePath(org)}><GoRepo/> New
                  </Button>
                </div>
              </div>
              <br/>
            </>
          }

          {blobs &&
            <>
              {blobs.map((blob, index) => {
                return (
                  <div key={blob.id}>
                    <h5>
                      <Link to={"/" + orgId + "/" + blob.alias}>{blob.name
                        ? <>{blob.name}</>
                        : <>{blob.alias}</>
                      }</Link>
                      &nbsp;&nbsp;
                      <Badge variant="secondary" className="title-tag-badge">{blob.visibility}</Badge>
                    </h5>
                    {blob.description &&
                      <div className="blob-item-row">
                        {blob.description}
                      </div>
                    }
                    <div className="blob-item-row">
                      ID: <code>{blob.id}</code> |
                      Status: <code>{blob.status}</code>
                    </div>
                    <div className="blob-item-row">
                      Domain: <code>{blob.domain}</code> |
                      Type: <code>{blob.type}</code> |
                      Format: <code>{blob.format}</code>
                    </div>
                    {blob.tags && blob.tags.length > 0 &&
                      <div className="blob-item-row">
                        {blob.tags.map((tag, index) => {
                          return (
                            <>
                              <Badge variant="secondary" className="tag-badge">{tag}</Badge>
                              &nbsp;
                            </>
                          );
                        })}
                      </div>
                    }
                    <hr/>
                  </div>
                );
              })}
            </>
          }
          {!blobs &&
            <>
              Loading blobs...
            </>
          }
        </Container>
      </>
    )
  }
}
