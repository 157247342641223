import React, { Component } from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { FiUser } from "react-icons/fi";

export default class Page extends Component {
  render() {
    const user = this.props.user;
    return (
      <>
        <Container className="blob-header-row repo-name-row org-profile-row">
          <Row>
            <Col sm={1}>
              <FiUser className="w-100 h-100"/>
            </Col>
            <Col>
              <h5>
                {user.name
                  ? <>{user.name}</>
                  : <>{user.alias}</>
                }
                &nbsp;&nbsp;
                {user.is_self &&
                  <>
                    &nbsp;
                    <Badge variant="secondary" className="title-tag-badge">self</Badge>
                  </>
                }
              </h5>
              {user.description &&
                <div className="org-profile-item-row">
                  {user.description}
                </div>
              }
              {user.url &&
                <div className="org-profile-item-row">
                  <a href={user.url}>{user.url}</a>
                </div>
              }
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
