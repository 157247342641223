import React, { Component } from 'react';
import { Card, Breadcrumb, ButtonGroup, Button, Row, Col, Alert, ListGroup, ListGroupItem, DropdownButton, Dropdown } from 'react-bootstrap';
import Octicon, {TriangleRight, History, FileDirectory, File, Trashcan } from '@github/octicons-react';
import { GiSave } from 'react-icons/gi';
import { FiCommand } from 'react-icons/fi';
import { GoTerminal, GoClippy, GoCode, GoStar, GoArrowBoth, GoArrowLeft } from 'react-icons/go';
import "./query.css";

import ReactGA from 'react-ga';

// Supported interpreter engines
import OrientDbSqlInterpreter from "components/graphs/generic/interpreters/orientdbSql";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-monokai";

export default class QueryView extends Component {
  constructor(props) {
    super(props);

    // Initialize available interpreters
    this.interpreters = {
      "orientdb_sql": new OrientDbSqlInterpreter()
    };

    // Apply default interpreter
    const firstInterpreter = this.props.revision.interpreters[0];

    this.state = {
      view: "query",
      mode: "query",
      queryValue: "",
      runningQuery: false,
      runErrorDetails: null,
      savingQuery: false,
      saveErrorDetails: null,
      savedQueries: [],
      defaultQueryRun: false,
      interpreter: this.interpreters[firstInterpreter["engine"]],
      interpreterDescr: firstInterpreter
    };

    // Expose querying interface
    this.props.shell.queryInterface = {
      runQuery: this.runQuery,
      getInterpreter: this.getInterpreter
    };

    // Load previously saved queries.
    this.loadSavedQueries();
  }

  /**
   * Preview Interface
   */

  runQuery = (query) => {
    // Apply requested query
    this.setState({
      queryValue: query
    }, () => {
      // Submit it for processing
      this.handleRun();
    });
  }

  getInterpreter = () => {
    return this.state.interpreter;
  }

  /**
   * Query run controls
   */

  onQueryValueChange = (newValue) => {
    this.setState({
      queryValue: newValue
    });
  }

  submitQueryInternal(props) {
    this.setState({
      runningQuery: true,
      runErrorDetails: null
    });

    const revisionId = props.revision.id;
    const query = this.state.queryValue;
    const apiMethod
      = "query" == this.state.mode
      ? this.props.app.api.revisionsIdDataQueryPost
      : this.props.app.api.revisionsIdDataCommandPost;

    const command = this.state.interpreter.prepareCommandBody(query);
    apiMethod.call(this.props.app.api, revisionId, command)
      .then(response => {
        this.setState({
          runningQuery: false
        });

        this.processQueryResult(response.data);
      }, error => {
        this.setState({
          runningQuery: false
        });

        if ( error.response ) {
          const details = this.state.interpreter.failureResponseToDetails(error.response.data);
          this.processQueryFailure(details);
        } else {
          const details = {errors: [error]};
          this.processQueryFailure(details);
        }
      });

    try {
      ReactGA.event({
        category: "query",
        action: query,
        label: revisionId
      });
    } catch(err) {}
  }

  handleRun = () => {
    if (!this.props.revision || !this.state.interpreter) {
      return;
    }

    this.submitQueryInternal(this.props);
  }

  processQueryResult(response) {
    // Convert response to internal graph representation.
    const graph = this.state.interpreter.successResponseToGraph(response);

    // Add result to the preview graph.
    this.props.shell.graph.incorporate(graph);

    // Notify renderer
    this.props.shell.previewInterface.incorporateDiff();
  }

  processQueryFailure(details) {
    // Update component state with error details.
    this.setState({
      runErrorDetails: details
    });
  }

  handleQueryMode = () => {
    this.setState({mode: "query"});
  }

  handleCommandMode = () => {
    this.setState({mode: "command"});
  }

  handleInterpreterChange(interpreter) {
    if ( !interpreter ) {
      return;
    }

    this.setState({
      interpreter: this.interpreters[interpreter["engine"]],
      interpreterDescr: interpreter
    });
  }

  /**
   * Query save controls
   */

  handleSave = () => {
    if (!this.props.revision) {
      return;
    }

    this.setState({
      savingQuery: true,
      saveErrorDetails: null
    });

    const revisionId = this.props.revision.id;
    const query = {
      "mode": this.state.mode,
      "interpreter": this.state.interpreterDescr,
      "value": this.state.queryValue,
      "default": false
    }
    this.props.app.api.revisionsIdMetadataAliasPost(revisionId, "queries", query)
      .then(response => {
        // Update state
        this.setState({
          savingQuery: false,
          saveErrorDetails: null
        });

        // Reload queries
        this.loadSavedQueries();
      }, error => {
        this.setState({
          savingQuery: true,
          saveErrorDetails: {
            errors: [ error ]
          }
        });
      });
  }

  /**
   * History controls
   */

  toggleSavedQueries = () => {
    let view = "query" == this.state.view ? "queries" : "query";
    this.setState({view: view});
  }

  loadSavedQueries() {
    const revisionId = this.props.revision.id;
    this.props.app.api.revisionsIdMetadataAliasGet(revisionId, "queries")
      .then(response => {
        this.processLoadedSavedQueries(response.data.items);
      }, error => {
        // Leaving in un-initialized state for now
      });
  }

  processLoadedSavedQueries(queries) {
    // Remember locally
    this.setState({savedQueries: queries});

    // Apply default query on load. Only do it once
    if ( !this.state.defaultQueryRun ) {
      this.setState({defaultQueryRun: true});

      for ( let query of queries ) {
        if ( query.default ) {
          this.runQuery(query.value);
          break;
        }
      }
    }
  }

  handleRunSavedQuery(query) {
    // Apply requested query
    this.setState({
      view: "query",
      mode: query.mode,
      queryValue: query.value,
      // Doing it in-place for now to avoid race condition between applying interpreter and running the query
      interpreter: this.interpreters[query.interpreter["engine"]],
      interpreterDescr: query.interpreter
    }, () => {
      // Submit it for processing
      this.handleRun();
    });
  }

  handleShowSavedQuery(query) {
    // Apply interpreter saved along with the query
    this.handleInterpreterChange(query.interpreter);

    // Apply requested query
    this.setState({
      view: "query",
      mode: query.mode,
      queryValue: query.value
    });
  }

  handleSavedQueryToggleDefault(query) {
    query.default = !query.default;
    this.props.app.api.revisionsMetadataIdPut(query.id, query)
      .then(response => {
        // Component state update does the trick
        this.setState({savedQueries: this.state.savedQueries});
      }, error => {
        // NEXT: Show an indication of update failure
      });
  }

  handleDeleteSavedQuery(query) {
    this.props.app.api.revisionsMetadataIdDelete(query.id)
      .then(response => {
        // Reload the list
        this.loadSavedQueries();
      }, error => {
        // NEXT: Show an indication of deletion failure
      });
  }

  /**
   * UX
   */

  render() {
    return (
      <>
        <table className="query-container">
          <tr>
            <td className="explore-content-pane">
              <div className={"query" !== this.state.view ? "hidden" : ""}>
                <AceEditor
                  name="query-editor"
                  width="100%"
                  height="116px"
                  mode="sql"
                  theme="monokai"
                  commands={[{
                    name: 'submitQuery',
                    bindKey: {win: 'Ctrl-Enter', mac: 'Command-Enter'},
                    exec: () => { this.handleRun() }
                  }]}
                  onChange={this.onQueryValueChange}
                  value={this.state.queryValue}
                />

                {this.state.runningQuery &&
                  <div>
                    <Alert variant="primary">
                      Running the query...
                    </Alert>
                  </div>
                }
                {this.state.runErrorDetails &&
                  <div>
                    <Alert variant="warning">
                      {this.state.runErrorDetails.errors.map((value, index) => {
                        return (
                          <div key={index}>
                            {value.message}
                          </div>
                        );
                      })}
                    </Alert>
                  </div>
                }

                {this.state.savingQuery &&
                  <div>
                    <Alert variant="primary">
                      Saving the query...
                    </Alert>
                  </div>
                }
                {this.state.saveErrorDetails &&
                  <div>
                    <Alert variant="warning">
                      {this.state.saveErrorDetails.errors.map((value, index) => {
                        return (
                          <div key={index}>
                            {value.message}
                          </div>
                        );
                      })}
                    </Alert>
                  </div>
                }
              </div>
              <div className={"queries" !== this.state.view ? "hidden" : ""}>
                <Card>
                  <ListGroup className="list-group-flush">
                    <ListGroupItem>
                      <Card.Text as="h6">Saved Queries</Card.Text>
                    </ListGroupItem>
                    <ListGroupItem>
                      <table className="appearance-templates-table">
                        <tbody>
                          {this.state.savedQueries.map((query, index) => {
                            return (
                              <tr>
                                <td className="template-template">
                                  <code>
                                    {query["value"].split("\n").map((line, index) => {
                                      return (
                                        <>
                                          {line}
                                          <br/>
                                        </>
                                      );
                                    })}
                                  </code>
                                </td>
                                <td className="saved-queries-default">
                                  {query.default &&
                                    <GoStar/>
                                  }
                                </td>
                                <td className="saved-queries-actions">
                                  <Button
                                    variant="light"
                                    onClick={() => this.handleRunSavedQuery(query)}><Octicon icon={TriangleRight} size="sm" />
                                  </Button>
                                  <Button
                                    variant="light"
                                    onClick={() => this.handleShowSavedQuery(query)}><GoClippy/>
                                  </Button>
                                  {this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision) &&
                                    <>
                                      <Button
                                        variant="light"
                                        onClick={() => this.handleSavedQueryToggleDefault(query)}><GoStar/>
                                      </Button>
                                      <Button
                                        variant="light"
                                        onClick={() => this.handleDeleteSavedQuery(query)}><Octicon icon={Trashcan} size="sm" />
                                      </Button>
                                    </>
                                  }
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </div>
            </td>
            <td className="explore-quick-actions">
              {this.props.revision &&
                <DropdownButton as={ButtonGroup} title={(<FiCommand/>)} variant="light" className="query-interpreters-dropdown">
                  {this.props.revision.interpreters.map((interpreter, index) => {
                    return (
                      <Dropdown.Item
                        active={this.state.interpreter.engine == interpreter.engine}
                        eventKey={interpreter.engine}
                        onClick={() => { this.handleInterpreterChange(interpreter) }}>
                        {interpreter.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              }

              <hr/>
              <Button
                variant={"query" === this.state.mode ? "secondary" : "light"}
                size="sm" onClick={this.handleQueryMode}><GoArrowLeft/></Button>
              {this.props.revision && this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision) &&
                <Button
                  variant={"command" === this.state.mode ? "secondary" : "light"}
                  size="sm" onClick={this.handleCommandMode}><GoArrowBoth/></Button>
              }
            </td>
            <td className="explore-vertical-divider">
            </td>
            <td className="explore-quick-actions">
              <Button
                variant={"query" === this.state.view ? "secondary" : "light"}
                size="sm" onClick={this.toggleSavedQueries}><Octicon icon={GoTerminal}/></Button>
              <Button
                variant={"queries" === this.state.view ? "secondary" : "light"}
                size="sm" onClick={this.toggleSavedQueries}><Octicon icon={History}/></Button>

              {"query" === this.state.view && this.props.revision &&
                <>
                  <hr/>
                  <Button variant="light" size="sm" onClick={this.handleRun}><Octicon icon={TriangleRight}/></Button>
                  {this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision) &&
                    <Button variant="light" size="sm" onClick={this.handleSave}><GiSave/></Button>
                  }
                </>
              }
            </td>
          </tr>
        </table>
      </>
    );
  }
}
