import React, { Component } from 'react';
import { Container, Row, Col, ButtonToolbar, Button, Alert, Card, DropdownButton, Dropdown } from 'react-bootstrap';
import RevisionDetailsView from 'components/controls/RevisionDetailsView/index';
import PreviewPane from 'components/graphs/dnn/preview/index';
import DetailsPane from 'components/graphs/dnn/details/index';
import Config from "context/config";
import Utils from "context/utils";
import axios from 'axios';

export default class BlobShell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: {
        visible: true,
        view: "components"
      },
      selection: {
        type: "graph",
        data: null
      },
      shellInterface: {
        updateDetails: this.updateDetails,
        updateSelection: this.updateSelection,
        toggleDetails: this.toggleDetails
      },
      components: null,
      modelAvailable: false,
      previewAvailable: false,
      loading: true,
      notFound: false,
      uploading: false,
      uploadingError: null,
      graph: null
    };

    this.hiddenModelUploadFileInput = React.createRef();

    this.loadComponents();
  }

  /**
   * Shell interface
   */

  updateDetails = (visible, view) => {
    this.setState({
      details: {
        visible: visible,
        view: view
      }
    });
  }

  updateSelection = (type, data) => {
    this.setState({
      selection: {
        type: type,
        data: data
      }
    });
  }

  toggleDetails = () => {
    let details = this.state.details;
    details.visible = !details.visible;
    this.setState({
      details: details
    });
  }

  /**
   * Data loading
   */

  loadComponents() {
    const body = {
      engine: "onnx",
      command: "describe"
    };
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        this.processComponents(response.data.components);
      }, error => {
        this.setState({ notFound: true });
      });
  }

  processComponents(components) {
    let modelAvailable = false;
    let previewAvailable = false;
    for ( const component of components ) {
      if ( "model.tar.gz" == component.path ) {
        modelAvailable = true;
      } else if ( "model.json" == component.path ) {
        previewAvailable = true;
      }
    }

    this.setState({
      loading: false,
      modelAvailable: modelAvailable,
      previewAvailable: previewAvailable,
      components: components
    });

    if ( previewAvailable ) {
      this.loadModelPreview();
    }
  }

  loadModelPreview() {
    const body = {
      engine: "onnx",
      command: "download",
      path: "model.json"
    };
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        var uint8array = new Uint8Array(response.binary);
        var decoded = new TextDecoder("utf-8").decode(uint8array);
        const graph = JSON.parse(decoded);
        this.setState({ graph: graph });
      }, error => {
        this.setState({ notFound: true });
      });
  }

  /**
   * Model Upload
   */

  handleModelUpload = () => {
    this.setState({
      uploading: false,
      uploadingError: null
    });

    this.hiddenModelUploadFileInput.current.click();
  }

  handleModelUploadChange = (event) => {
    if ( 1 != event.target.files.length ) {
      return;
    }
    const file = event.target.files[0];
    event.target.value = null;

    // Check for large files
    if ( file.size > Config.maxUploadFileSize ) {
      this.setState({
        uploading: false,
        uploadingError: "Maximum supported model archive size is 4MB. Use SDK to upload larger models."
      });
      return;
    }

    // Upload the model
    this.setState({
      uploading: true,
      uploadingError: null
    });
    const reader = new FileReader();
    reader.onload = (e) => {
      const body = {
        engine: "onnx",
        command: "upload",
        path: "model.tar.gz",
        size: file.size
      };
      this.props.app.api.revisionsIdDataCommandPost(this.props.revision.id, body, reader.result)
        .then( response => {
          // Uploading state stays on until background processing of the upload completes
          // Initiate periodic refreshing of operation status
          const operationId = response.data.operation_id;
          this.refreshUploadStatus(operationId);
        }, error => {
          this.setState({
            uploading: false,
            uploadingError: error.message
          });
        });
    };
    reader.readAsArrayBuffer(file);
  }

  refreshUploadStatus(operationId) {
    this.props.app.api.operationsIdGet(operationId)
      .then( response => {
        const operation = response.data.operation;
        if ( this.props.app.api.isOperationStillInProgress(operation) ) {
          // Refresh in a bit...
          setTimeout(() => {
            this.refreshUploadStatus(operationId);
          }, 3000);
        } else if ( this.props.app.api.hasOperationCompleted(operation) ) {
          // Get out of uploading state
          this.setState({
            uploading: false,
            uploadingError: null
          });

          // Reload model components and preview to reflect changes caused by uploading new model
          this.loadComponents();
        } else if ( this.props.app.api.hasOperationFailed(operation) ) {
          this.setState({
            uploading: false,
            uploadingError: "Failed to process model archive. Check archive contents."
          });
        }
      }, error => {
        // Refresh in a bit...
        setTimeout(() => {
          this.refreshUploadStatus(operationId);
        }, 3000);
      });
  }

  /**
   * Model Download
   */

  initiateModelComponentDownload(path) {
    const body = {
      engine: "onnx",
      command: "download",
      path: path
    };
    this.props.app.api.revisionsIdDataQueryPost(this.props.revision.id, body)
      .then( response => {
        this.completeModelComponentDownload(response.binary, path, response.headers["content-type"])
      }, error => {
        // Fail silently for now
      });
  }

  completeModelComponentDownload(data, fileName, type) {
    // Create an invisible A element
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);

    // Set the HREF to a Blob representation of the data to be downloaded
    a.href = window.URL.createObjectURL(
      new Blob([data], { type })
    );

    // Use download attribute to set set desired file name
    a.setAttribute("download", fileName);

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  }

  /**
   * UX
   */

  render() {
    if ( this.state.notFound ) {
      return (
        <>
          <Container className="blob-header-row content-row">
            Failed to load revision data.
          </Container>
        </>
      );
    }

    if ( this.state.loading || null == this.state.components ) {
      return (
        <>
          <Container className="blob-header-row content-row">
            Loading revision data...
          </Container>
        </>
      );
    }

    return (
      <>
        {!this.state.uploading &&
          ( this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision) ||
            ( this.state.components && this.state.components.length > 0 ) ) &&
          <Container className="blob-header-row content-row">
            <div className="blob-download-row">
              <span className="float-right">
                <ButtonToolbar>
                  {this.props.app.api.canUpdateRevision(this.props.blob, this.props.revision) &&
                    <>
                      <span className="text-muted text-note" style={{"line-height": "31px"}}>
                        Upload model (up to 4 MB)
                      </span>&nbsp;&nbsp;&nbsp;
                      <Button
                        size="sm"
                        title="Preview"
                        variant="success"
                        onClick={this.handleModelUpload}>
                        Upload
                      </Button>
                      <input type="file"
                        ref={this.hiddenModelUploadFileInput}
                        onChange={this.handleModelUploadChange}
                        accept="application/gzip, .gz"
                        multiple={false}
                        style={{display:"none"}}
                      />
                    </>
                  }

                  {this.state.components && this.state.components.length > 0 &&
                    <>
                      &nbsp;&nbsp;&nbsp;
                      <DropdownButton alignRight size="sm" title="Download" variant="success" id="download">
                        {this.state.components.map((component, index) => {
                          return (
                            <>
                              {component.size > Config.maxDownloadFileSize &&
                                <Dropdown.Item>
                                  {component.path} ({Utils.formatBytes(component.size)})
                                </Dropdown.Item>
                              }
                              {component.size <= Config.maxDownloadFileSize &&
                                <Dropdown.Item
                                  onClick={() => { this.initiateModelComponentDownload(component.path) }}
                                >
                                  <strong>{component.path}</strong> ({Utils.formatBytes(component.size)})
                                </Dropdown.Item>
                              }
                            </>
                          );
                        })}
                      </DropdownButton>
                    </>
                  }
                </ButtonToolbar>
              </span>
            </div>
            {this.state.uploadingError &&
              <div>
                &nbsp;
                <Alert variant="warning">
                  {this.state.uploadingError}
                </Alert>
              </div>
            }
          </Container>
        }
        {this.state.uploading &&
          <Container className="blob-header-row content-row">
            <Alert variant="primary">
              Uploading selected model...
            </Alert>
          </Container>
        }

        {!this.state.modelAvailable && !this.state.previewAvailable &&
          <Container className="blob-header-row content-row">
            <Card>
              <Card.Header>
                <RevisionDetailsView
                  app={this.props.app}
                  revision={this.props.revision}
                  shell={null}
                />
              </Card.Header>
              <Card.Body>
                <Alert variant="secondary">
                  No model has been uploaded to this blob yet. <br/>
                  <br/>
                  Start model upload here ... <br/>
                  <br/>
                  ... or upload model using <a href="https://pypi.org/project/blobhub/" target="_blank">BlobHub Python SDK</a>:
                  <br/>
                  <code>
                    blob = Blob(org_id="{this.props.org ? this.props.org.alias : ""}", blob_id="{this.props.blob.alias}", api_key="...") <br/>
                    revision = blob.revisions.latest() <br/>
                    onnx = Onnx(revision=revision) <br/>
                    model = Model.from_local_file(onnx=onnx, path="/path/to/model.onnx") <br/>
                    onnx.upload(model=model) <br/>
                  </code>
                </Alert>
              </Card.Body>
            </Card>
          </Container>
        }

        {this.state.components && this.state.components.length > 0 &&
          <Container className="blob-header-row content-row">
            <Row>
              <Col sm={this.state.details.visible ? 8 : 12}>
                <PreviewPane
                  app={this.props.app}
                  blob={this.props.blob}
                  revision={this.props.revision}
                  shell={this.state}
                />
              </Col>
              {this.state.details.visible &&
                <Col sm={4}>
                  <DetailsPane
                    app={this.state}
                    blob={this.props.blob}
                    revision={this.props.revision}
                    shell={this.state}
                  />
                </Col>
              }
            </Row>
          </Container>
        }
      </>
    )
  }
}
