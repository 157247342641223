import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Table, Button } from 'react-bootstrap';
import { GoInfo } from 'react-icons/go';
import View from "./view";

class ViewItem extends Component {
  constructor(props) {
    super(props);
  }

  formatRelativeViewUrl(view) {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;
    const path = `\/${orgId}\/${blobId}\/publish\/${view.id}`;
    return path;
  }

  render() {
    return (
      <>
        <tr>
          <td>
            <div class="d-flex">
              <div>
                <strong>{this.props.view.name}</strong> <br/>
                ID: <code>{this.props.view.id}</code> <br/>
              </div>

              <div class="ml-auto">
                <Link to={this.formatRelativeViewUrl(this.props.view)}>
                  <Button variant="light" className="btn-icon"><GoInfo /></Button>
                </Link>
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export default class PublishView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      views: null
    }

    this.loadViews();
  }

  loadViews() {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;

    // Load views
    this.props.app.api.blobsIdMetadataAliasGet(orgId, blobId, "views")
      .then(response => {
        // Remember data
        this.setState({
          views: response.data.items
        });
      }, error => {
        // Remember data
        this.setState({
          views: null
        });
      });
  }

  findActiveView(viewId) {
    for ( const view of this.state.views ) {
      if ( viewId == view.id ) {
        return view;
      }
    }
    return null;
  }

  /**
   * UX
   */

  render() {
    if ( null == this.state.views ) {
      return (
        <>
          <Container className="blob-header-row content-row">
            Loading views...
          </Container>
        </>
      );
    }

    const activeView = this.findActiveView(this.props.sectionId);

    return (
      <>
        {!activeView &&
          <Container className="blob-header-row content-row">
            {this.state.views && 0 != this.state.views.length &&
              <Table hover bordered>
                <tbody>
                  {this.state.views.map((view, index) => {
                    return (
                      <ViewItem
                        app={this.props.app}
                        blob={this.props.blob}
                        match={this.props.match}
                        view={view}
                      />
                    );
                  })}
                </tbody>
              </Table>
            }
            {this.state.views && 0 == this.state.views.length &&
              <>
                No views found for the blob
              </>
            }
          </Container>
        }
        {activeView &&
          <View
            app={this.props.app}
            blob={this.props.blob}
            match={this.props.match}
            view={activeView}
          />
        }
      </>
    );
  }
}
