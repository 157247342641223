import React, { Component } from 'react';
import { Container, Alert, Row, Col, Card, Button } from 'react-bootstrap';
import { GiMeshNetwork } from "react-icons/gi";
import { GoDatabase } from "react-icons/go";
import { AiOutlineTable } from "react-icons/ai";
import Holder from 'react-holder';
import ReactGA from 'react-ga';
import "./index.css";

export default class Page extends Component {
  componentDidMount() {
    ReactGA.pageview('/home');
  }

  render() {
    return (
      <>
        <Row className="main-page-banner-row">
          <Container className="d-flex h-100">
            <Row className="justify-content-center align-self-center w-100">
              <Col sm={5}>
                <Card.Img variant="top" src="/static/main/logo_bg.png" />
              </Col>
              <Col className="justify-content-center align-self-center">
                <h1>BlobHub</h1>
                <h4>Home for Structured and Versioned Data</h4>
                <p>
                  BlobHub is a platform for the data-driven world.
                  It aims at democratizing creation, collaboration, and sharing of structured data
                  making it available at fingertips of potential consumers.
                </p>
              </Col>
            </Row>
          </Container>
        </Row>

        <Container>
          <Row className="main-page-section-title-row">
            <Col>
              <h4>Data Stores</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Card>
                <Card.Body>
                  <Card.Title><GiMeshNetwork className="main-card-icon"/>Graph Data</Card.Title>
                  <Card.Text>
                    Managed linked data repositories at your fingertips with collaboration and publishing
                    capabilities available out of the box.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4}>
              <Card>
                <Card.Body>
                  <Card.Title><GoDatabase className="main-card-icon"/>Datasets</Card.Title>
                  <Card.Text>
                    Repositories of data in its own native formats ready for loading, integration, and consumption
                    in your applications.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={4}>
              <Card>
                <Card.Body>
                  <Card.Title><AiOutlineTable className="main-card-icon"/>Structured Data</Card.Title>
                  <Card.Text>
                    Traditional relational storage for respective use cases. Ability to quickly ingest, and share
                    structured blobs of data.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="main-page-section-title-row">
            <Col>
              <h4>Featured Blobs</h4>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Card>
                <div className="d-flex justify-content-center align-items-center w-100 main-card-banner">
                  <Card.Img src="/static/main/featured_onnx_bidaf.png" style={{width: "80%"}}/>
                </div>
                <Card.Body>
                  <Card.Title>ONNX Graphs</Card.Title>
                  <Card.Text>
                    Example snapshot of DNN computational graphs exported in ONNX format.
                  </Card.Text>
                  <Card.Text>
                    <a href="/onnx-vision-models">ONNX Vision</a> <br/>
                    <a href="/onnx-text-models">ONNX Text</a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={3}>
              <Card>
                <div className="d-flex justify-content-center align-items-center w-100 main-card-banner">
                  <Card.Img src="/static/main/featured_world_wikimedia.png" style={{width: "97%"}}/>
                </div>
                <Card.Body>
                  <Card.Title>World Data</Card.Title>
                  <Card.Text>
                    Graph blobs populated with queryable data from various domains of human knowledge.
                  </Card.Text>
                  <Card.Text>
                    <a href="/world/wikimedia">World/WikiMedia</a> <br/>
                    <a href="/world/movies">Media/Movies</a> <br/>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={3}>
              <Card>
                <div className="d-flex justify-content-center align-items-center w-100 main-card-banner">
                  <Card.Img src="/static/main/featured_nlp_semantic.png" style={{width: "97%"}}/>
                </div>
                <Card.Body>
                  <Card.Title>Semantic Graphs</Card.Title>
                  <Card.Text>
                    Semantic graphs containing information about concepts of natural language.
                  </Card.Text>
                  <Card.Text>
                    <a href="/nlp/conceptnet">NLP/ConceptNet</a> <br/>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={3}>
              <Card>
                <div className="d-flex justify-content-center align-items-center w-100 main-card-banner">
                  <Card.Img src="/static/main/featured_nlp_record.png" style={{width: "93%"}}/>
                </div>
                <Card.Body>
                  <Card.Title>NLP Datasets</Card.Title>
                  <Card.Text>
                    Selection of natively-visualized NLP datasets available for exploration.
                  </Card.Text>
                  <Card.Text>
                    <a href="/SuperGLUE">SuperGLUE</a> <br/>
                    <a href="/SuperGLUE/ReCoRD">SuperGLUE/ReCoRD</a> <br/>
                    <a href="/SuperGLUE/MultiRC">SuperGLUE/MultiRC</a> <br/>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="main-page-section-title-row">
            <Col>
              <hr/>
              <h4>Managed ONNX Blobs</h4>
            </Col>
          </Row>

          <Row className="main-page-first-subsection-title-row">
            <Col>
              <h5>Model Workflow</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={6}>
              <Card.Img variant="top" src="/static/main/onnx_lifecycle.png" />
            </Col>
            <Col sm={4}>
              <p>
                The purpose of <a href="https://onnx.ai/" target="_blank">ONNX blob</a> is to provide strongly versioned
                storage for deep neural network models.
              </p>
              <p>
                Centralized repository of models controlled by flexible permission system enables clean separation
                between training and inference infrastructures for running efficient ML operation.
              </p>
            </Col>
          </Row>

          <Row className="main-page-subsection-title-row">
            <Col>
              <h5>Model Operations</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={6}>
              <Card.Img variant="top" src="/static/main/onnx_ui_preview.png" />
            </Col>
            <Col sm={4}>
              <p>
                BlobHub provides native interface for operating with ONNX models including:
                <ul>
                  <li>organization and blob creation;</li>
                  <li>access management;</li>
                  <li>revision operations;</li>
                  <li>basic model upload and download capabilities;</li>
                  <li>model preview.</li>
                </ul>
              </p>
              <p>
                Check out these example models that come with notebooks demonstrating it's adoption:
                <ul>
                  <li>
                    <a href="https://blobhub.io/onnx-vision-models/super-resolution" target="_blank">
                      onnx-vision-models/super-resolution
                    </a>
                  </li>
                  <li>
                    <a href="https://blobhub.io/onnx-text-models/bi-att-flow" target="_blank">
                      onnx-text-models/bi-att-flow
                    </a>
                  </li>
                </ul>
              </p>
            </Col>
          </Row>

          <Row className="main-page-subsection-title-row">
            <Col>
              <h5>Python SDK</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={4}>
              <Card.Img variant="top" src="/static/main/onnx_download_code.png" />
            </Col>
            <Col sm={6}>
              <p>
                Built on top of the foundation of BlobHub REST API, Python SDK enables full set of operations over ONNX
                blobs.
              </p>
              <p>
                BlobHub Python SDK can be downloaded&nbsp;
                <a href="https://pypi.org/project/blobhub/" target="_blank">here</a>.
              </p>
              <p>
                See the following notebook&nbsp;
                <a target="_blank" href="https://colab.research.google.com/github/blobhubio/blobhub-example-notebooks/blob/master/onnx_vision_models/super_resolution/end_to_end.ipynb">
                  <img src="https://colab.research.google.com/assets/colab-badge.svg" alt="Open In Colab"/>
                </a>&nbsp;
                for an end-to-end example of generating, uploading, downloading and inferring data using&nbsp;
                <a href="https://blobhub.io/onnx-vision-models/super-resolution" target="_blank">
                  onnx-vision-models/super-resolution
                </a>&nbsp;
                model.
              </p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="main-page-section-title-row">
            <Col>
              <hr/>
              <h4>Managed Graph Blobs</h4>
            </Col>
          </Row>
          <Row className="main-page-first-subsection-title-row">
            <Col>
              <h5>Ingestion</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={6}>
              <Card.Img variant="top" src="/static/main/graph_api_query.png" />
            </Col>
            <Col sm={4}>
              <p>Simple REST API provides access to blobs and revisions with the ability to populate and interact with
                revision data.</p>
            </Col>
          </Row>
          <Row className="main-page-subsection-title-row">
            <Col>
              <h5>Ad-hoc Analysis</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={6}>
              <Card.Img variant="top" src="/static/main/graph_ui_query.png" />
            </Col>
            <Col sm={4}>
              <p>Essential tools are provided for data exploration and visualization.</p>
              <p>Assisted querying and convenient traversal controls enable quick analysis of revision content.</p>
              <p>Flexible appearance templating system allows graph representation to be customized for the needs
                of a specific graph.</p>
            </Col>
          </Row>
          <Row className="main-page-subsection-title-row">
            <Col>
              <h5>Publishing</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={8}>
              <iframe
                title="Sample view"
                src="https://widgets.blobhub.io/v1/graph-view/39a20322-3430-4494-b1fb-b11efaf5e3e4"
                width="100%"
                height="550px"
                frameBorder="0">
              </iframe>
            </Col>
            <Col sm={4}>
              <p>Prepared revision data can be embedded into external application with the use of one of
                BlobHub Widgets.</p>
              <p>Minimal code snippet is required to add widget to a web page.</p>
              <p><Card.Img variant="top" src="/static/main/graph_widget_code.png" /></p>
              <p>Widget data stays in sync with the state of revision it is rendered against.</p>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="main-page-section-title-row">
            <Col>
              <hr/>
              <h4>Concepts</h4>
            </Col>
          </Row>
          <Row className="main-page-first-subsection-title-row">
            <Col>
              <h5>Organizations and Blobs</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={6}>
              <Card.Img variant="top" src="/static/main/blob_concept.png" />
            </Col>
            <Col sm={4}>
              <p><strong>Blob</strong> is an essential primitive in BlobHub universe.
                It represents unit of data users want to store, version, and interact with.</p>
              <p>Blobs are grouped into <strong>Organizations</strong> allowing users to form collections and
                efficiently manage access to those.</p>
            </Col>
          </Row>
          <Row className="main-page-subsection-title-row">
            <Col>
              <h5>Revision Lifecycle</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={6}>
              <Card.Img variant="top" src="/static/main/revision_lifecycle.png" />
            </Col>
            <Col sm={4}>
              <p><strong>Revision</strong> represents a point in time checkpoint of a blob.
                Revision lifecycle consists of <strong>Draft</strong>, <strong>Commit</strong>,
                and <strong>Snapshot</strong> stages.</p>
              <p>Revision use cases and interface mutate over time as it transitions through
                its lifecycle.</p>
            </Col>
          </Row>
          <Row className="main-page-subsection-title-row">
            <Col>
              <h5>Blob Access</h5>
            </Col>
          </Row>
          <Row>
            <Col sm={1}></Col>
            <Col sm={6}>
              <Card.Img variant="top" src="/static/main/blob_access.png" />
            </Col>
            <Col sm={4}>
              <p>
                BlobHub offers flexible privacy controls.
                Blobs can be kept <strong>private</strong> (shared with individual contributors or consumers) or
                made <strong>publicly</strong> available to all BlobHub users.
              <p>
              </p>
                Programmatic read/write access (to public and private blobs) can be enabled with the use of
                <strong> API keys</strong>.
              </p>
            </Col>
          </Row>
        </Container>

        <br/>
      </>
    )
  }
}
