import React, { Component } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Octicon, {Info} from '@github/octicons-react';

export default class InfoTooltip extends Component {
  render() {
    return (
      <>
        <OverlayTrigger
          placement={this.props.placement ? this.props.placement : "top"}
          overlay={
            <Tooltip>
              {this.props.text}
            </Tooltip>
          }>
          <span>
            <Octicon icon={Info}/>
          </span>
        </OverlayTrigger>
      </>
    );
  }
}
