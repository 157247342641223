import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container, Navbar, Nav, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { GoScreenNormal, GoScreenFull } from 'react-icons/go';
import { AuthRoute, PrivateRoute } from 'components/controls/PrivateRoute/index';
import Footer from 'components/controls/Footer/index';
import Main from '../Main/index';
import Profile from '../Profile/index';
import OrgCreate from '../Org/create';
import Org from '../Org/index';
import Blob from '../Blob/index';
import BlobCreate from '../Blob/create';
import BlobRevisions from '../Blob/revisions';
import Auth from '../Auth/callback';
import UserMenu from '../Auth/user';
import Api from 'context/api';
import { Terms, Cookies, Privacy, Beta } from "pages/Main/legal";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      screenMode: "normal",
      authenticated: false,
      auth: null,
      api: null
    };

    this.state.api = new Api(
      this.state,
      (state) => { this.setState(state) },
      () => { return this.state });
  }

  handleScreenModeChange = (value, event) => {
    this.setState({
      screenMode: value
    });
  }

  render() {
    return (
      <Router>
        <Navbar bg="dark" variant="dark" expand="lg" className="shadow-sm">
          <Container>
            <Navbar.Brand href="/">BlobHub</Navbar.Brand>
            <Nav className="mr-auto">
              <Nav.Link href="/p/blobhub">Featured</Nav.Link>
            </Nav>
            <Nav>
              <UserMenu
                app={this.state}
              />
            </Nav>
          </Container>
        </Navbar>

        <Route exact path="/" component={Main} />

        <Switch>
          <Route exact path="/terms" render={(props) => <Terms/>} />
          <Route exact path="/privacy" render={(props) => <Privacy/>} />
          <Route exact path="/cookies" render={(props) => <Cookies/>} />
          <Route exact path="/beta" render={(props) => <Beta/>} />

          <Route exact path="/auth/:provider/callback"
            render={(props) => <Auth {...props} app={this.state} />}
          />

          <AuthRoute exact
            path="/p/:userId/org/create"
            app={this.state}
            component={OrgCreate}
          />

          <AuthRoute exact
            path={[
              "/p/:userId",
              "/p/:userId/:section",
              "/p/:userId/:section/:sectionId",
            ]}
            app={this.state}
            component={Profile}
          />

          <AuthRoute exact
            path={[
              "/:orgId",
              "/:orgId/settings",
              "/:orgId/settings/:sectionId",
            ]}
            app={this.state}
            component={Org}
          />

          <AuthRoute exact
            path="/:orgId/blob/create"
            app={this.state}
            component={BlobCreate}
          />

          <AuthRoute exact
            path={[
              "/:orgId/:blobId",
              "/:orgId/:blobId/:section",
              "/:orgId/:blobId/:section/:sectionId"
            ]}
            app={this.state}
            component={Blob}
          />
        </Switch>

        <Footer/>
      </Router>
    );
  }
}
