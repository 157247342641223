import React, { Component } from 'react';
import { Container, Row, Col, Badge, Button, Card, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { GoOrganization } from "react-icons/go";
import Navigation from "context/nav";

export default class Page extends Component {
  render() {
    const user = this.props.user;
    const orgs = this.props.orgs;
    return (
      <>
        <Container className="blob-header-row content-row blob-list-row">
          {user.is_self &&
            <>
              <Row>
                <Col sm={2}>
                </Col>
                <Col sm={8}>
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <strong>BlobHub</strong> is currently available in <strong>Beta</strong>.
                        By using the site
                      </Card.Text>
                      <Card.Text>
                        <Form.Check type="checkbox" checked disabled label={<>
                          I agree to the following <a href="/beta">Beta Terms and Conditions</a>
                        </>}/>
                        <Form.Check type="checkbox" checked disabled label={<>
                          I agree to the following <a href="/terms">Terms of Use</a>
                        </>}/>
                        <Form.Check type="checkbox" checked disabled label={<>
                          I agree to the following <a href="/privacy">Privacy Policy</a>
                        </>}/>
                        <Form.Check type="checkbox" checked disabled label={<>
                          I agree to the following <a href="/cookies">Cookie Policy</a>
                        </>}/>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={2}>
                </Col>
              </Row>
              <br/>

              <div className="content-row">
                &nbsp;
                <div className="float-right">
                  <span className="text-muted text-note">
                    Create a new organization to store blobs
                  </span>&nbsp;&nbsp;&nbsp;
                  <Button
                    variant="success"
                    size="sm"
                    href={Navigation.orgCreatePath(user)}><GoOrganization/> New
                  </Button>
                </div>
              </div>
              <br/>
            </>
          }

          {orgs &&
            <>
              {orgs.map((org, index) => {
                return (
                  <div key={org.id}>
                    <h5>
                      <Link to={`/${org.alias}`}>{org.name
                        ? <>{org.name}</>
                        : <>{org.alias}</>
                      }</Link>
                      &nbsp;&nbsp;&nbsp;
                      <Badge variant="secondary" className="title-tag-badge">{org.visibility}</Badge>
                      &nbsp;&nbsp;
                      <Badge variant="secondary" className="title-tag-badge">{org.type}</Badge>
                    </h5>
                    {org.description &&
                      <div className="blob-item-row">
                        {org.description}
                      </div>
                    }
                    {org.url &&
                      <div className="blob-item-row">
                        <a href={org.url}>{org.url}</a>
                      </div>
                    }
                    <hr/>
                  </div>
                );
              })}
            </>
          }
          {!orgs &&
            <>
              Loading organizations...
            </>
          }
        </Container>
      </>
    )
  }
}
