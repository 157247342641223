import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import axios from 'axios';
import Config from "context/config";

import RevisionDetailsView from 'components/controls/RevisionDetailsView/index';
import InfoTooltip from 'components/controls/InfoTooltip/index'

import DownloaderView from './downloader';
import './index.css';

import MultiRCNlpFormat from './formats/multirc';
import RecordNlpFormat from './formats/record';
import SchemaNlpFormat from './formats/schema';

const { SearchBar } = Search;

export default class DatasetPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activateObject: this.activateObject,
      activeObjectIndex: null,
      activeObjectDescr: null,
      activeObjectData: null,
      activeObjectRecords: null,
      activeRecordQuery: null
    };

    this.createFormat();
    this.activateInitial(0);
  }

  createFormat() {
    switch (this.props.blob.format) {
      case "record": {
        this.format = new RecordNlpFormat(this, this.props.shell.data);
        break;
      }
      case "multirc": {
        this.format = new MultiRCNlpFormat(this, this.props.shell.data);
        break;
      }
      case "schema": {
        this.format = new SchemaNlpFormat(this, this.props.shell.data);
        break;
      }
      default: {}
    }
  }

  /**
   * Object Selection
   */

  activateInitial(index) {
    if ( 0 === this.props.shell.data.objects.length ) {
      return;
    }

    this.state.activeObjectIndex = index;
    this.state.activeObjectDescr = this.props.shell.data.objects[index];

    // NEXT: Add caching
    this.loadObject(index);
  }

  activateObject = (index) => {
    if ( 0 === this.props.shell.data.objects.length ) {
      return;
    }

    this.setState({
      activeObjectIndex: index,
      activeObjectDescr: this.props.shell.data.objects[index]
    });

    // NEXT: Add caching
    this.loadObject(index);
  }

  loadObject(index) {
    const objectDescr = this.props.shell.data.objects[index];
    axios.get(`${Config.static_base_url()}/revisions/${this.props.revision.id}/${objectDescr["path"]}`)
      .then( response => {
        // Convert JSONL into records
        var records = [];
        var dataLines = response.data.split("\n");
        for (var dataLine of dataLines) {
          try {
            var record = JSON.parse(dataLine);
            records.push(record);
          } catch(err) {
            // Not a JSON record. Ignoring for now
          }
        }

        // Update component state
        this.setState({
          activeObjectData: response.data,
          activeObjectRecords: records
        });
      })
      .catch( error => {
      }
    );
  }

  /**
   * Rendering section
   */

  paginationTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        Records <strong>{ from } to { to }</strong> of { size }
        {this.state.activeObjectDescr && this.state.activeObjectDescr.stats &&
         this.state.activeObjectDescr.stats.total_records !== this.state.activeObjectDescr.stats.presented_records &&
          <>
            &nbsp;&nbsp;
            <InfoTooltip
              text={"The entire object contains " + this.state.activeObjectDescr.stats.total_records + " records"}/>
          </>
        }
      </span>
    );
  }

  render() {
    if ( !this.format ) {
      // This should never be presented for a considerable amount of time unless critical error occurs.
      return (<></>);
    }

    const columns = this.format.getColumns();

    const paginationOptions = {
      custom: true,
      totalSize: this.state.activeObjectRecords ? this.state.activeObjectRecords.length : 0,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      paginationTotalRenderer: this.paginationTotal
    };

    return (
      <>
        {this.props.shell.data.objects.length > 0 &&
          <DownloaderView
            app={this.props.app}
            parent={this.state}
            blob={this.props.blob}
            revision={this.props.revision}
            shell={this.props.shell}
          />
        }

        <Card>
          <Card.Header>
            {this.props.revision
              ? <RevisionDetailsView
                  app={this.props.app}
                  revision={this.props.revision}
                  shell={null}
                />
              : <>Loading revision info...</>
            }
          </Card.Header>
          <Card.Body>
            {this.state.activeObjectRecords ? (
              <PaginationProvider
                pagination={ paginationFactory(paginationOptions) }
              >
                {
                  ({
                    paginationProps,
                    paginationTableProps
                  }) => (
                    <ToolkitProvider
                      bootstrap4
                      keyField={this.format.getKeyField()}
                      data={this.state.activeObjectRecords}
                      columns={columns}
                      search
                    >
                      {
                        props => (
                          <div>
                            <BootstrapTable
                              bordered={false}
                              defaultSorted={this.format.getDefaultSorted()}
                              expandRow={this.format.getRowExpansionOptions()}
                              {...props.baseProps}
                              {...paginationTableProps}
                            />
                            <div>
                              <SearchBar
                                placeholder="search..."
                                {...props.searchProps} /> &nbsp;&nbsp;

                              <PaginationTotalStandalone
                                { ...paginationProps }
                              />

                              <span className="float-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </span>
                            </div>
                          </div>
                        )
                      }
                    </ToolkitProvider>
                  )
                }
              </PaginationProvider>
            ) : (
              <>
                Loading object...
              </>
            )}
          </Card.Body>
        </Card>
      </>
    )
  }
}
