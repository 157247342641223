import React, { Component } from 'react';
import { Container, Row, Col, Card, Badge, Alert } from 'react-bootstrap';
import Octicon, {Book, Database, History, Gear, Repo, Sync} from '@github/octicons-react';
import ExplorePane from 'components/graphs/generic/explore/index';
import DetailsPane from 'components/graphs/generic/details/index';
import Graph from 'components/graphs/generic/explore/graph';
import "./index.css";

export default class BlobShell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: {
        visible: false,
        view: "summary",
        data: null
      },
      graph: new Graph(),
      appearance: null,
      shellInterface: {
        setAppearance: this.setAppearance,
        updateDetails: this.updateDetails,
        toggleDetails: this.toggleDetails
      },
      queryInterface: null,
      previewInterface: null,
      graphCtrlInterface: null,
      appearanceInterface: null
    };
  }

  /**
   * Shell interface
   */

  setAppearance = (appearance) => {
    // Remember locally
    this.setState({
      appearance: appearance
    });

    // Update the graph
    this.state.previewInterface.refreshAppearance();
  }

  updateDetails = (visible, view, data) => {
    this.setState({
      details: {
        visible: visible,
        view: view,
        data: data
      }
    });
  }

  toggleDetails = () => {
    let details = this.state.details;
    details.visible = !details.visible;
    this.setState({
      details: details
    });
  }

  /**
   * UX
   */

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.state.previewInterface.activate();
  }

  render() {
    return (
      <>
        <div
          className={
            this.props.app.screenMode === "normal"
            ? "container blob-header-row content-row"
            : "blob-header-row content-row"
          }>
          <Row>
            <Col sm={this.state.details.visible ? 8 : 12}>
              <ExplorePane
                app={this.props.app}
                blob={this.props.blob}
                revision={this.props.revision}
                shell={this.state}
              />
            </Col>
            {this.state.details.visible &&
              <Col sm={4}>
                <DetailsPane
                  app={this.state}
                  blob={this.props.blob}
                  revision={this.props.revision}
                  shell={this.state}
                />
              </Col>
            }
          </Row>
        </div>
      </>
    )
  }
}
