import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, ToggleButtonGroup, ToggleButton, Alert } from 'react-bootstrap';
import { GoRepo, GoLock } from "react-icons/go";
import Select from "react-select";
import ReactGA from 'react-ga';
import Navigation from 'context/nav';

export default class Page extends Component {
  constructor(props) {
    super(props);

    this.domainOptions = [];
    this.typeOptions = [];
    this.formatOptions = [];

    this.state = {
      org: null,
      notFound: false,
      blob: {
        alias: "",
        visibility: "private",
        domain: null,
        type: null,
        format: null
      },
      data: {
        domain: null,
        type: null,
        format: null
      },
      progress: false,
      error: null
    };

    this.loadData();

    ReactGA.pageview('/blob/create');
  }

  loadData() {
    const orgId = this.props.match.params.orgId;

    // Query org profile
    this.props.app.api.orgsIdGet(orgId)
      .then(response => {
        this.processOrg(response.data.org);
      }, error => {
        this.setState({ notFound: true });
      });

    // Query data domains
    this.props.app.api.usersIdLimitsGet("me")
      .then(response => {
        this.processDomains(response.data.limits.data_domains.value);
      }, error => {
        this.setState({ notFound: true });
      });
  }

  processOrg(org) {
    // Prevent blob creation for alien orgs
    if ( !org.is_self ) {
      this.setState({ notFound: true });
      return;
    }

    // Remember org profile
    this.setState({ org: org });
  }

  processDomains(domains) {
    // Generate options
    for ( let domain of domains ) {
      domain.value = domain.id;
      for ( let type of domain.types ) {
        type.value = type.id;
        for ( let format of type.formats ) {
          format.value = format.id;
        }
      }
    }
    this.domainOptions = domains;
    this.typeOptions = this.domainOptions[0].types;
    this.formatOptions = this.typeOptions[0].formats;

    // Update initial state
    this.setState({
      data: {
        domain: this.domainOptions[0],
        type: this.typeOptions[0],
        format: this.formatOptions[0]
      }
    });
    let blob = this.state.blob;
    blob.domain = this.domainOptions[0].value;
    blob.type = this.typeOptions[0].value;
    blob.format = this.formatOptions[0].value;
    this.setState({blob: blob});
  }

  handleCreateSubmit = (event) => {
    event.preventDefault();

    // Check form validity
    const form = event.currentTarget;
    if ( form.checkValidity() === false ) {
      return;
    }

    this.setState({
      progress: true,
      error: null
    });

    // Create blob
    this.props.app.api.blobsPost(this.state.org.id, this.state.blob)
      .then(response => {
        this.proceedToCreatedBlob();
      }, error => {
        this.setState({
          progress: false,
          error: error.response.data.message
        });
      });
  }

  proceedToCreatedBlob() {
    Navigation.push(this.props.history, Navigation.blobPath(this.state.org, this.state.blob));
  }

  render() {
    if ( this.state.notFound ) {
      // Redirect to self profile if org is not found
      Navigation.push(this.props.history, Navigation.selfUserProfilePath());
      return (
        <>
          Redirecting to self profile page...
        </>
      );
    }

    if ( !this.state.org || !this.domainOptions ) {
      return (
        <>
          <Container className="blob-header-row header-message-row">
            Fetching relevant details...
          </Container>
        </>
      );
    }

    const orgId = this.props.match.params.orgId;
    return (
      <>
        <Container>
          <Row>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Container className="blob-header-row header-message-row">
                <h5>
                  Create a New Blob
                </h5>
                <div>
                  Blob contains structured data that can be iterated on by contributors, versioned, and consumed.
                </div>
                <hr/>
              </Container>
              <Container className="blob-header-row">
                <Form onSubmit={this.handleCreateSubmit}>
                  <Form.Group>
                    <Form.Label>
                      <strong>Alias</strong> <span className="text-muted">(required)</span>
                    </Form.Label>
                    <Row>
                      <Col sm={3}>
                        <Form.Control
                          required
                          type="text"
                          size="sm"
                          autoFocus
                          value={this.state.blob.alias}
                          onChange={(event) => {
                            let blob = this.state.blob;
                            blob.alias = event.target.value;
                            this.setState({blob: blob});
                          }}
                        />
                      </Col>
                    </Row>
                    <Form.Text muted>
                      Blob aliases should be concise and meaningful.
                    </Form.Text>
                    <Form.Text muted>
                      Alias must confirm to the following requirements:
                      <ul>
                        <li>minimum of 6 characters;</li>
                        <li>maximum of 32 characters;</li>
                        <li>only alphanumeric characters along with <strong>-</strong>,<strong>_</strong> are allowed;</li>
                        <li>must be unique within the organization.</li>
                      </ul>
                    </Form.Text>
                  </Form.Group>
                  <hr/>

                  <Form.Group>
                    <Form.Label>
                      <strong>Visibility</strong>
                    </Form.Label>
                    <Form.Check
                      type="radio"
                      name="visibility"
                      id="visibility-public"
                      label={<><GoRepo/> Public</>}
                      checked={"public" == this.state.blob.visibility}
                      onChange={(event) => {
                        let blob = this.state.blob;
                        blob.visibility = event.target.checked ? "public" : "private";
                        this.setState({blob: blob});
                      }}
                    />
                    <Form.Text muted>
                      <string>Public</string> blobs are visible to the world but only chosen users can contribute to it.
                    </Form.Text>
                    <Form.Check
                      type="radio"
                      name="visibility"
                      id="visibility-private"
                      label={<><GoLock/> Private</>}
                      checked={"private" == this.state.blob.visibility}
                      onChange={(event) => {
                        let blob = this.state.blob;
                        blob.visibility = event.target.checked ? "private" : "public";
                        this.setState({blob: blob});
                      }}
                    />
                    <Form.Text muted>
                      <string>Private</string> blobs are invisible by default with both producers and consumers being added manually.
                    </Form.Text>
                  </Form.Group>
                  <hr/>

                  <Form.Group>
                    <Form.Label>
                      <strong>Data</strong>
                    </Form.Label>
                    <Row>
                      <Col sm={4}>
                        <Select
                          options={this.domainOptions}
                          value={this.state.data.domain}
                          onChange={(option) => {
                            let blob = this.state.blob;
                            blob.domain = option.value;
                            blob.type = option.types[0].value;
                            blob.format = option.types[0].formats[0].value;
                            this.setState({blob: blob});

                            let data = this.state.data;
                            data.domain = option;
                            data.type = option.types[0];
                            data.format = option.types[0].formats[0];
                            this.setState({data: data});

                            this.typeOptions = option.types;
                          }}
                        />
                      </Col>
                    </Row>
                    <Form.Text muted>
                      <string>Domain</string> is a top level of blob classification and determines structure of data.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <Col sm={4}>
                        <Select
                          options={this.typeOptions}
                          value={this.state.data.type}
                          onChange={(option) => {
                            let blob = this.state.blob;
                            blob.type = option.value;
                            blob.format = option.formats[0].value;
                            this.setState({blob: blob});

                            let data = this.state.data;
                            data.type = option;
                            data.format = option.formats[0];
                            this.setState({data: data});

                            this.formatOptions = option.formats;
                          }}
                        />
                      </Col>
                    </Row>
                    <Form.Text muted>
                      <string>Type</string> refers to a specific substructure within a chosen domain.
                    </Form.Text>
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <Col sm={4}>
                        <Select
                          options={this.formatOptions}
                          value={this.state.data.format}
                          onChange={(option) => {
                            let blob = this.state.blob;
                            blob.format = option.value;
                            this.setState({blob: blob});

                            let data = this.state.data;
                            data.format = option;
                            this.setState({data: data});
                          }}
                        />
                      </Col>
                    </Row>
                    <Form.Text muted>
                      <string>Format</string> is the lowest level that determines exact notation of data stored.
                    </Form.Text>
                  </Form.Group>
                  <hr/>

                  {this.state.progress &&
                    <>
                      <Alert variant="primary">
                        Creating blob...
                      </Alert>
                    </>
                  }

                  {this.state.error &&
                    <>
                      <Alert variant="warning">
                        {this.state.error}
                      </Alert>
                      <hr/>
                    </>
                  }

                  {!this.state.progress &&
                    <>
                      <Form.Group>
                        <Button
                          type="submit"
                          variant="success"
                          size="sm">
                          Create Blob
                        </Button>
                      </Form.Group>
                    </>
                  }
                </Form>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
