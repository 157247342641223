import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PreviewPane from 'components/dataset/nlp/preview/index';
import Config from "context/config";
import axios from 'axios';

export default class BlobShell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      loading: true,
      notFound: false,
      details: {
        visible: false
      }
    };
  }

  /**
   * Data loading
   */

  loadRevisionData() {
    this.loadDatasetOverview();
  }

  loadDatasetOverview() {
    axios.get(`${Config.static_base_url()}/revisions/${this.props.revision.id}/data.json`)
      .then( response => {
        this.setState({
          loading: false,
          data: response.data
        });
      }, error => {
        this.setState({ notFound: true });
      });
  }

  /**
   * UX
   */

  componentDidMount() {
    if ( this.props.revision ) {
      this.loadRevisionData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.revision !== prevProps.revision) {
      this.loadRevisionData();
    }
  }

  render() {
    if ( this.state.notFound ) {
      return (
        <>
          <Container className="blob-header-row content-row">
            Failed to load revision data.
          </Container>
        </>
      );
    }

    if ( this.state.loading || null == this.state.data ) {
      return (
        <>
          <Container className="blob-header-row content-row">
            Loading revision data...
          </Container>
        </>
      );
    }

    return (
      <>
        <Container className="blob-header-row content-row">
          <Row>
            <Col sm={12}>
              <PreviewPane
                app={this.props.app}
                blob={this.props.blob}
                revision={this.props.revision}
                shell={this.state}
              />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
