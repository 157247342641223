import BaseNlpFormat from './base';

export default class NlpFormat extends BaseNlpFormat {
  getKeyField() {
    return this.data.schema.id_field;
  }

  getColumns() {
    var columns = [];
    for (const schemaColumn of this.data.schema.columns) {
      const column = {
        dataField: schemaColumn.property,
        text: schemaColumn.name,
        sort: schemaColumn.sort,
        headerStyle: () => {
          return { width: schemaColumn.width ? schemaColumn.width : null };
        }
      };
      columns.push(column);
    }
    return columns;
  }

  getDefaultSorted() {
    return [{
      dataField: this.getKeyField(),
      order: "asc"
    }];
  }
}
