import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, ToggleButtonGroup, ToggleButton, Alert } from 'react-bootstrap';
import { GoRepo, GoLock } from "react-icons/go";
import ReactGA from 'react-ga';
import Navigation from 'context/nav';

export default class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      notFound: false,
      org: {
        alias: "",
        visibility: "private"
      },
      progress: false,
      error: null
    };

    this.loadData();

    ReactGA.pageview('/org/create');
  }

  loadData() {
    const userId = this.props.match.params.userId;

    // Query user profile
    this.props.app.api.usersIdGet(userId)
      .then(response => {
        this.setState({ user: response.data.user });
      }, error => {
        this.setState({ notFound: true });
      });
  }

  handleCreateSubmit = (event) => {
    event.preventDefault();

    // Check form validity
    const form = event.currentTarget;
    if ( form.checkValidity() === false ) {
      return;
    }

    this.setState({
      progress: true,
      error: null
    });

    // Create organization
    this.props.app.api.orgsPost(this.state.org)
      .then(response => {
        this.proceedToCreatedOrg();
      }, error => {
        this.setState({
          progress: false,
          error: error.response.data.message
        });
      });
  }

  proceedToCreatedOrg() {
    Navigation.push(this.props.history, Navigation.orgPath(this.state.org));
  }

  render() {
    if ( this.state.notFound ) {
      // Redirect to self profile if user is not found
      Navigation.push(this.props.history, Navigation.selfUserProfilePath());
      return (
        <>
          Redirecting to self profile page...
        </>
      );
    }

    if ( !this.state.user ) {
      return (
        <>
          <Container className="blob-header-row header-message-row">
            Fetching relevant details...
          </Container>
        </>
      );
    }

    const orgId = this.props.match.params.orgId;
    return (
      <>
        <Container>
          <Row>
            <Col sm={2}></Col>
            <Col sm={8}>
              <Container className="blob-header-row header-message-row">
                <h5>
                  Create a New Organization
                </h5>
                <div>
                  An organization contains blobs and allows owners to configure access to those in a single place.
                </div>
                <hr/>
              </Container>
              <Container className="blob-header-row">
                <Form onSubmit={this.handleCreateSubmit}>
                  <Form.Group>
                    <Form.Label>
                      <strong>Alias</strong> <span className="text-muted">(required)</span>
                    </Form.Label>
                    <Row>
                      <Col sm={3}>
                        <Form.Control
                          required
                          type="text"
                          size="sm"
                          autoFocus
                          value={this.state.org.alias}
                          onChange={(event) => {
                            let org = this.state.org;
                            org.alias = event.target.value;
                            this.setState({org: org});
                          }}
                        />
                      </Col>
                    </Row>
                    <Form.Text muted>
                      Organization aliases should be concise and meaningful.
                    </Form.Text>
                    <Form.Text muted>
                      Alias must confirm to the following requirements:
                      <ul>
                        <li>minimum of 6 characters;</li>
                        <li>maximum of 32 characters;</li>
                        <li>only alphanumeric characters along with <strong>-</strong>,<strong>_</strong> are allowed;</li>
                        <li>must be globally unique.</li>
                      </ul>
                    </Form.Text>
                  </Form.Group>
                  <hr/>

                  <Form.Group>
                    <Form.Label>
                      <strong>Visibility</strong>
                    </Form.Label>
                    <Form.Check
                      type="radio"
                      name="visibility"
                      id="visibility-public"
                      label={<><GoRepo/> Public</>}
                      checked={"public" == this.state.org.visibility}
                      onChange={(event) => {
                        let org = this.state.org;
                        org.visibility = event.target.checked ? "public" : "private";
                        this.setState({org: org});
                      }}
                    />
                    <Form.Text muted>
                      <string>Public</string> organizations are visible to the world but only chosen users can contribute to it.
                    </Form.Text>
                    <Form.Check
                      type="radio"
                      name="visibility"
                      id="visibility-private"
                      label={<><GoLock/> Private</>}
                      checked={"private" == this.state.org.visibility}
                      onChange={(event) => {
                        let org = this.state.org;
                        org.visibility = event.target.checked ? "private" : "public";
                        this.setState({org: org});
                      }}
                    />
                    <Form.Text muted>
                      <string>Private</string> organizations are invisible by default with both producers and consumers being added manually.
                    </Form.Text>
                  </Form.Group>
                  <hr/>

                  {this.state.progress &&
                    <>
                      <Alert variant="primary">
                        Creating organization...
                      </Alert>
                    </>
                  }

                  {this.state.error &&
                    <>
                      <Alert variant="warning">
                        {this.state.error}
                      </Alert>
                      <hr/>
                    </>
                  }

                  {!this.state.progress &&
                    <>
                      <Form.Group>
                        <Button
                          type="submit"
                          variant="success"
                          size="sm">
                          Create Organization
                        </Button>
                      </Form.Group>
                    </>
                  }
                </Form>
              </Container>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
