import React, { Component } from 'react';
import { Card, Breadcrumb, ButtonGroup, Button } from 'react-bootstrap';
import Octicon, {Info, Graph, X} from '@github/octicons-react';
import './index.css';

import SummaryView from './summary';
import VertexView from './vertex';
import EdgeView from './edge';

export default class DetailsPane extends Component {
  render() {
    return (
      <>
        {"summary" == this.props.shell.details.view &&
          <SummaryView
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            shell={this.props.shell}
          />
        }
        {"vertex" == this.props.shell.details.view &&
          <VertexView
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            shell={this.props.shell}
          />
        }
        {"edge" == this.props.shell.details.view &&
          <EdgeView
            app={this.props.app}
            blob={this.props.blob}
            revision={this.props.revision}
            shell={this.props.shell}
          />
        }
      </>
    );
  }
}
