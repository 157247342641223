import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from "axios";
import marked from 'marked';
import "./legal.css";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: null
    };

    this.loadContent();
  }

  loadContent() {
    axios.get(this.contentPath())
      .then( response => {
        this.setState({ content: {__html: marked(response.data)} });
      }, error => {
      });
  }

  render() {
    return (
      <>
        <Container className="blob-header-row content-row">
          <Row>
            <Col className="legal-content" dangerouslySetInnerHTML={this.state.content}>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export class Cookies extends Page {
  contentPath() {
    return "/static/legal/cookies.md";
  }
}

export class Terms extends Page {
  contentPath() {
    return "/static/legal/terms.md";
  }
}

export class Privacy extends Page {
  contentPath() {
    return "/static/legal/privacy.md";
  }
}

export class Beta extends Page {
  contentPath() {
    return "/static/legal/beta.md";
  }
}
