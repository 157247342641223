import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import CloseControl from './close';

export default class View extends Component {
  render() {
    return (
      <Card>
        <Card.Header>
          Graph Details
          <span className="float-right">
            <CloseControl shell={this.props.shell} />
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Text as="h6">General</Card.Text>
          <Card.Text>
            <table className="properties">
              <tr>
                <td className="prop-name-column">Domain</td>
                <td className="prop-value-column">graph</td>
              </tr>
              <tr>
                <td className="prop-name-column">Type</td>
                <td className="prop-value-column">generic</td>
              </tr>
            </table>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}
