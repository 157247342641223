/**
 * Class to store static application configuration
 */
export default class Config {

  // TODO: Rename all methods to match naming convention.

  /**
   * General
   */

  // Unlocks debugging features and verbose logging.
  static debug = true;

  static apiBaseUrl() {
    return "https://api.blobhub.io";
  }

  static static_base_url() {
    return "https://static.blobhub.io";
  }

  static authGoogleClientId() {
    return "190294057847-km6i4usvtespqqs8o4avrbkl9oaglv86.apps.googleusercontent.com";
  }

  /**
   * Internal
   */

  static authLoginBaseUrl() {
    return "https://blobhub-public-access.auth.us-west-2.amazoncognito.com";
  }

  static authClientId() {
    return "vln2o673m78n2enga3b1p4rdp";
  }

  static authRedirectUrl() {
    return "https://api.blobhub.io/v1/auth/cognito/callback";
  }

  /**
   * ONNX
   */

  static maxUploadFileSize = 4 * 1024 * 1024;
  static maxDownloadFileSize = 4 * 1024 * 1024;
}
