import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import ReactGA from 'react-ga';

export default class Page extends Component {
  componentDidMount = () => {
    // Record auth page
    ReactGA.pageview('/auth');

    // Read query arguments
    const query = this.getQuery();
    const refreshToken = query.get("refresh_token");
    const accessToken = query.get("access_token");
    const expiresAt = parseInt(query.get("expires_at"));

    // Authenticate user
    this.props.app.api.authenticate(false, refreshToken, accessToken, expiresAt);

    // Redirect to home
    var pagePath = window.location.protocol + "//" + window.location.host + "/";
    window.location.assign(pagePath);
  }

  getQuery() {
    return new URLSearchParams(this.props.location.search);
  }

  render() {
    return (
      <>
        <br/>
        <Container className="main-logo">
          Authenticating...
        </Container>
      </>
    );
  }
}
