import React, { Component } from 'react';
import { Card, Breadcrumb, ButtonGroup, Button } from 'react-bootstrap';
import RevisionDetailsView from 'components/controls/RevisionDetailsView/index';
import QueryView from './query';
import PreviewView from './preview';

export default class ExplorePane extends Component {
  render() {
    return (
      <>
        <Card>
          <Card.Header>
            {this.props.revision
              ? <RevisionDetailsView
                  app={this.props.app}
                  blob={this.props.blob}
                  revision={this.props.revision}
                  shell={this.props.shell}
                />
              : <>Loading revision info...</>
            }
          </Card.Header>
          <Card.Body>
            <QueryView
              app={this.props.app}
              blob={this.props.blob}
              revision={this.props.revision}
              shell={this.props.shell}
            />
            <hr/>

            <PreviewView
              app={this.props.app}
              blob={this.props.blob}
              revision={this.props.revision}
              shell={this.props.shell}
            />
          </Card.Body>
        </Card>
      </>
    );
  }
}
