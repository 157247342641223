import React, { Component } from 'react';
import { Nav, NavDropdown, Button } from 'react-bootstrap';
import Octicon, {Person} from '@github/octicons-react';
import { GoogleLogin } from 'react-google-login';
import Config from "context/config";
import './user.css'

export default class UserMenu extends Component {
  responseGoogleSuccess = (response) => {
    // Check that response contains all the info we need
    if ( !response || !response.accessToken
      || !response.profileObj || !response.profileObj.email || !response.profileObj.name
      || !response.profileObj.googleId ) {
      return;
    }

    // Perform authentication
    this.props.app.api.authGoogleSigninPost(
      response.accessToken,
      response.profileObj.googleId,
      response.profileObj.name,
      response.profileObj.email);
  }

  responseGoogleFailure = (response) => {
    // No op for now
  }

  signout = () => {
    // Sign out
    this.props.app.api.signout();

    // Navigate to home page
    // NEXT: This should be done more gracefully via router which is not currently accessible from here.
    window.location.assign("/");
  }

  render() {
    const userDropDownTitle = (
      <span className="justify-content-end global-user-avatar">
        <Octicon icon={Person} size="medium"/>
      </span>
    );
    return (
      <>
        <>
          {this.props.app.authenticated && this.props.app.auth && !this.props.app.auth.anonymous ? (
            <NavDropdown title={userDropDownTitle} id="basic-nav-dropdown" alignRight>
              <NavDropdown.Item href="/p/me">Profile</NavDropdown.Item>
              <NavDropdown.Divider/>
              <NavDropdown.Item onClick={() => this.signout()}>Sign out</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <GoogleLogin
              clientId={Config.authGoogleClientId()}
              render={renderProps => (
                <Button
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  variant="outline-success"
                  size="sm"
                  className="btn-signin">Sign in</Button>
              )}
              buttonText="Login"
              onSuccess={this.responseGoogleSuccess}
              onFailure={this.responseGoogleFailure}
              cookiePolicy={"single_host_origin"}
            />
          )}
        </>
      </>
    )
  }
}
