import React, { Component } from 'react';
import { Container, Card, Button, Tabs, Tab } from 'react-bootstrap';
import { GoBook, GoCode, GoInfo, GoLink } from 'react-icons/go';
import { FiCommand, FiEdit } from 'react-icons/fi';
import { FaEye, FaList, FaCss3 } from 'react-icons/fa';
import "./view.css";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-monokai";

export default class View extends Component {
  constructor(props) {
    super(props);
  }

  /**
   * UX
   */

  formatWidgetUrl() {
    return `https://widgets.blobhub.io/v1/graph-view/${this.props.view.id}`;
  }

  formatCodeSnippet() {
    const code
      = "<iframe \n"
      + `  title=\"${this.props.view.name}\"\n`
      + `  src=\"${this.formatWidgetUrl()}\"\n`
      + "  width=\"70%\"\n"
      + "  height=\"550px\"\n"
      + "  frameBorder=\"0\">\n"
      + "</iframe>";
    return code;
  }

  formatAppearanceConfig() {
    const config = JSON.stringify(this.props.view.appearance, null, 2);
    return config;
  }

  formatDetailsConfig() {
    const config = JSON.stringify(this.props.view.details, null, 2);
    return config;
  }

  render() {
    return (
      <>
        <Container className="blob-header-row content-row main-tabs">
          <Card>
            <Card.Header>
              <GoInfo/>
              &nbsp;<strong>General</strong>
            </Card.Header>
            <Card.Body>
              <div class="d-flex">
                <div>
                  <strong>{this.props.view.name}</strong> <br/>
                  ID: <code>{this.props.view.id}</code>
                </div>

                <div class="ml-auto">
                  <Button
                    variant="success" size="sm" disabled
                    onClick={this.handleCreateInit}><FiEdit/>
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>

          <Tabs defaultActiveKey="preview" className="public-item-content-row">
            <Tab eventKey="preview" title={<><FaEye/> Preview</>}>
              <div className="blob-header-row public-item-content-row">
                <Card>
                  <Card.Body>
                    <div className="float-right public-item-preview-link">
                      <a href={this.formatWidgetUrl()} target="__blank">
                        <GoLink />&nbsp;&nbsp;Full-screen preview
                      </a>
                    </div>
                    <iframe
                      title={this.props.view.name}
                      src={this.formatWidgetUrl()}
                      width="100%"
                      height="650px"
                      frameBorder="0">
                    </iframe>
                  </Card.Body>
                </Card>
              </div>
            </Tab>

            <Tab eventKey="code" title={<><GoCode/> Code Snippet</>}>
              <div className="blob-header-row public-item-content-row">
                <Card>
                  <Card.Body>
                    <AceEditor
                      name="query-editor"
                      width="100%"
                      height="116px"
                      mode="sql"
                      theme="monokai"
                      readOnly="true"
                      value={this.formatCodeSnippet()}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>

            <Tab eventKey="queries" title={<><FaList/> Queries</>}>
              <div className="blob-header-row public-item-content-row">
                <Card>
                  <Card.Body>
                    <table className="appearance-templates-table">
                      <tbody>
                        {this.props.view.queries.map((query, index) => {
                          return (
                            <tr>
                              <td className="view-query-index">
                                <strong>{index + 1}</strong>
                              </td>
                              <td className="saved-queries-actions view-query-details">
                                Revision ID: <code>{query.revision_id}</code> <br/>
                                <FiCommand/> <code>{query.interpreter.name} ({query.interpreter.version})</code> interpreter<br/>
                              </td>
                              <td className="template-template">
                                <code>
                                  {query["value"].split("\n").map((line, index) => {
                                    return (
                                      <>
                                        {line}
                                        <br/>
                                      </>
                                    );
                                  })}
                                </code>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Card.Body>
                </Card>
              </div>
            </Tab>

            <Tab eventKey="appearance" title={<><FaCss3/> Appearance</>}>
              <div className="blob-header-row public-item-content-row">
                <Card>
                  <Card.Body>
                    <AceEditor
                      name="query-editor"
                      width="100%"
                      height="550px"
                      mode="sql"
                      theme="monokai"
                      readOnly="true"
                      value={this.formatAppearanceConfig()}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>

            <Tab eventKey="details" title={<><GoInfo/> Details</>}>
              <div className="blob-header-row public-item-content-row">
                <Card>
                  <Card.Body>
                    <AceEditor
                      name="query-editor"
                      width="100%"
                      height="166px"
                      mode="sql"
                      theme="monokai"
                      readOnly="true"
                      value={this.formatDetailsConfig()}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Tab>
          </Tabs>
        </Container>
      </>
    );
  }
}
