import React, { Component } from 'react';
import { Card, Breadcrumb, ButtonGroup, Button, Row, Col } from 'react-bootstrap';
import Octicon, { GitMerge, Eye, Trashcan, Dash } from '@github/octicons-react';

import GraphCtrlVis from './graphCtrlVis';
import AppearanceView from './appearance';

export default class PreviewView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: "graph"
    };

    this.props.shell.previewInterface = {
      incorporateDiff: this.incorporateDiff,
      refreshAppearance: this.refreshAppearance,
      activate: this.activate,
      clear: this.clear
    };
  }

  /**
   * Preview Interface
   */

  incorporateDiff = () => {
    this.props.shell.graphCtrlInterface.incorporateDiff();
  }

  refreshAppearance = () => {
    this.props.shell.graphCtrlInterface.refreshAppearance();
  }

  activate = () => {
    this.props.shell.graphCtrlInterface.activate();
  }

  clear = () => {
    this.props.shell.graph.clear();
    this.props.shell.graphCtrlInterface.clear();
    this.props.shell.shellInterface.updateDetails(false, "summary", null);
  }

  /**
   * UX section
   */

  handleGraphView = () => {
    this.setState({ view: "graph" });
  }

  handleAppearanceView = () => {
    this.setState({ view: "appearance" });
  }

  handleClear = () => {
    this.clear();
  }

  render() {
    return (
      <>
        <table>
          <tr>
            <td className="explore-content-pane">
              <div className={"graph" !== this.state.view ? "hidden" : ""}>
                <GraphCtrlVis
                  shell={this.props.shell}
                  graph={this.props.shell.graph} />
              </div>
              <div className={"appearance" !== this.state.view ? "hidden" : ""}>
                <AppearanceView
                  app={this.props.app}
                  blob={this.props.blob}
                  revision={this.props.revision}
                  shell={this.props.shell} />
              </div>
            </td>
            <td className="explore-quick-actions">
              <Button
                variant={"graph" === this.state.view ? "secondary" : "light"}
                onClick={this.handleGraphView}><Octicon icon={GitMerge} size="sm" /></Button>
              <Button
                variant={"appearance" === this.state.view ? "secondary" : "light"}
                onClick={this.handleAppearanceView}><Octicon icon={Eye} size="sm" /></Button>
              <hr/>
              <Button
                variant="light"
                onClick={this.handleClear}><Octicon icon={Trashcan} size="sm" /></Button>
            </td>
          </tr>
        </table>
      </>
    );
  }
}
