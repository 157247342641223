import * as graphlib from 'graphlib'

export default class OrientDbSqlGraphModeInterpreter {
  static engine = "orientdb_sql";

  constructor() {
    this.engine = OrientDbSqlGraphModeInterpreter.engine;
  }

  /**
   * Query I/O handling
   */

  prepareCommandBody(command) {
    return {
      "engine": this.engine,
      "command": command
    }
  }

  successResponseToGraph(response) {
    var graph = new graphlib.Graph({
      directed: true,
      compound: false,
      multigraph: true
    });

    // Parse vertices
    var graphVerticesIndexById = {};
    for ( const resultVertex of response["result"]["vertices"] ) {
      // Construct vertex
      const graphVertex = {
        id: resultVertex["@rid"],
        class: resultVertex["@class"] || "Vertex",
        edges: {
          incoming: [],
          outgoing: []
        },
        properties: {},
        native: resultVertex
      };
      for ( let propertyName of Object.keys(resultVertex) ) {
        let property = resultVertex[propertyName];
        if ( propertyName.startsWith("@") ) {
          continue;
        } else if ( propertyName.startsWith("in_") && Array.isArray(property) ) {
          let edgeClass = ( "in_" == propertyName ) ? "Edge" : propertyName.substring(3);
          graphVertex.edges.incoming.push({
            class: edgeClass,
            ids: property,
            count: resultVertex[`${propertyName}_count`] || property.length
          });
        } else if ( propertyName.startsWith("out_") && Array.isArray(property) ) {
          let edgeClass = ( "out_" == propertyName ) ? "Edge" : propertyName.substring(4);
          graphVertex.edges.outgoing.push({
            class: edgeClass,
            ids: property,
            count: resultVertex[`${propertyName}_count`] || property.length
          });
        } else if ( propertyName.startsWith("in_") && propertyName.endsWith("_count") ) {
          continue;
        } else if ( propertyName.startsWith("out_") && propertyName.endsWith("_count") ) {
          continue;
        } else {
          graphVertex.properties[propertyName] = property;
        }
      }
      graphVerticesIndexById[graphVertex.id] = graphVertex;

      // Add to graph
      graph.setNode(graphVertex.id, graphVertex);
    }

    // Parse edges
    for ( const resultEdge of response["result"]["edges"] ) {
      // Construct edge
      const graphEdge = {
        id: resultEdge["@rid"],
        class: resultEdge["@class"] || "Edge",
        properties: {},
        native: resultEdge
      };
      for ( let propertyName of Object.keys(resultEdge) ) {
        let property = resultEdge[propertyName];
        if ( propertyName.startsWith("@") || propertyName == "in" || propertyName == "out" ) {
          continue;
        } else {
          graphEdge.properties[propertyName] = property;
        }
      }

      // Add to graph
      const outVertex = graphVerticesIndexById[resultEdge["out"]];
      const inVertex = graphVerticesIndexById[resultEdge["in"]];
      if ( !outVertex || !inVertex ) {
        continue;
      }
      graph.setEdge(outVertex.id, inVertex.id, graphEdge, graphEdge.id);
    }

    return graph;
  }

  failureResponseToDetails(response) {
    var details = {
      errors: []
    };

    if ( response["details"] ) {
      for ( const item of response["details"] ) {
        details.errors.push({
          message: item["content"]
        });
      }
    } else if ( response["message"] ) {
      details.errors.push({
        message: response["message"]
      });
    } else {
      details.errors.push({
        message: "Unexpected error has occurred"
      });
    }

    return details;
  }

  /**
   * Query generators
   */

  queryTraverseOut(vertex, edgeClass) {
    return `TRAVERSE OUT("${edgeClass}") FROM ${vertex.id} MAXDEPTH 1 LIMIT 16`;
  }

  queryTraverseIn(vertex, edgeClass) {
    return `TRAVERSE IN("${edgeClass}") FROM ${vertex.id} MAXDEPTH 1 LIMIT 16`;
  }
}
