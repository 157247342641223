import React, { Component } from 'react';
import { Container, Row, Col, Tabs, Tab, Card, Badge, Alert } from 'react-bootstrap';
import BlobHeader from "./header";
import BlobRevisions from "./revisions";
import BlobMarkdown from "./md";
import BlobSettings from "./settings/settings";
import Navigation from "context/nav";
import './index.css';

import ReactGA from 'react-ga';

// Various blob types. All are imported but only the right one is loaded dynamically.
// - domain: graph | type: generic
import GraphGenericShell from 'components/graphs/generic/shell/index';
import GraphGenericTabs from 'components/graphs/generic/shell/tabs';
import GraphGenericPublish from 'components/graphs/generic/publish/index';
// - domain: graph | type: dnn
import GraphDnnTabs from 'components/graphs/dnn/shell/tabs';
import GraphDnnShell from 'components/graphs/dnn/shell/index';
// - domain: datasets | type: nlp
import DatasetNlpTabs from 'components/dataset/nlp/shell/tabs';
import DatasetNlpShell from 'components/dataset/nlp/shell/index';

export default class Blob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notFound: false,
      org: null,
      blob: null,
      revision: null,
      forcedRevision: false,
      loadBlob: this.loadBlob,
      loadLatestRevision: this.loadLatestRevision
    };

    this.loadOrg();
  }

  componentDidMount() {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;
    ReactGA.pageview("/" + orgId + "/" + blobId);
  }

  loadOrg() {
    const orgId = this.props.match.params.orgId;

    // Query org details
    this.props.app.api.orgsIdGet(orgId)
      .then(response => {
        // Remember data
        this.setState({
          org: response.data.org
        }, this.loadBlob);
      }, error => {
        // Remember data
        this.setState({
          notFound: true
        });
      });
  }

  loadBlob = () => {
    const orgId = this.props.match.params.orgId;
    const blobId = this.props.match.params.blobId;

    // Query blob details
    this.props.app.api.blobsIdGet(orgId, blobId)
      .then(response => {
        const blob = response.data.blob;

        // Remember data
        this.setState({
          blob: blob
        });

        if ( this.props.app.api.hasBlobReachedTerminalSuccessStatus(blob) ) {
          // Load latest revision
          this.loadLatestRevision();
        } else if ( this.props.app.api.isBlobInUpdatingStatus(blob) ) {
          // Load latest revision
          this.loadLatestRevision();

          // Schedule refresh in a bit
          setTimeout(() => {
            this.loadBlob();
          }, 3000);
        } else if ( this.props.app.api.hasBlobReachedTerminalFailureStatus(blob) ) {
          // Nothing for now
        } else if ( this.props.app.api.isBlobInDeletingStatus(blob) ) {
          // Navigate back to org
          Navigation.push(this.props.history, Navigation.orgPath(this.state.org));
        } else {
          // Schedule refresh in a bit
          setTimeout(() => {
            this.loadBlob();
          }, 3000);
        }
      }, error => {
        // Failed to load repo
        this.setState({
          notFound: true
        });
      });
  }

  loadLatestRevision = () => {
    if ( !this.state.blob ) {
      return;
    }

    // Default to blob revision
    let revisionId = this.state.blob["latest_revision_id"];

    // Use the one requested explicitly if user came from "../revisions/..".
    const section = this.props.match.params.section || "default";
    const sectionId = this.props.match.params.sectionId;
    if ( "revisions" == section && sectionId ) {
      revisionId = sectionId;
      this.setState({forcedRevision: true});
    }

    // Confirm that specific revision is being referred to
    if ( !revisionId ) {
      return;
    }

    // Load latest revision
    this.props.app.api.revisionsIdGet(revisionId)
      .then(response => {
        // Remember data
        this.setState({
          revision: response.data.revision
        });
      }, error => {});
  }

  /**
   * Rendering section
   */

  matchContentType(domain, type) {
    const section = this.props.match.params.section || "default";
    const sectionId = this.props.match.params.sectionId;
    return (
      this.state.blob.domain == domain &&
      this.state.blob.type == type
    );
  }

  matchGenericSection(tabSection, requestedSection) {
    return requestedSection == tabSection;
  }

  render() {
    if ( this.state.notFound ) {
      return (
        <>
          <Container className="blob-header-row header-message-row">
            Requested blob has not been found.
          </Container>
        </>
      );
    }

    if ( null == this.state.blob ) {
      return (
        <>
          <Container className="blob-header-row header-message-row">
            Loading blob...
          </Container>
        </>
      );
    } else {
      if ( this.props.app.api.hasBlobReachedTerminalSuccessStatus(this.state.blob) ) {
        // Just moving on.
      } else if ( this.props.app.api.isBlobInUpdatingStatus(this.state.blob) ) {
        // Just moving on.
      } else if ( this.props.app.api.hasBlobReachedTerminalFailureStatus(this.state.blob) ) {
        return (
          <>
            <Container className="blob-header-row header-message-row">
              Failed to create the blob. Please try again.
            </Container>
          </>
        );
      } else {
        return (
          <>
            <Container className="blob-header-row header-message-row">
              The blob is under construction. Please wait...
            </Container>
          </>
        );
      }
    }

    const section = this.props.match.params.section || "default";
    const sectionId = this.props.match.params.sectionId;

    let tabSection = section;
    if ( "revisions" == section && sectionId ) {
      tabSection = "default";
    }

    return (
      <>
        <BlobHeader
          app={this.props.app}
          org={this.state.org}
          blob={this.state.blob}
          revision={this.state.revision}
          match={this.props.match}
        />

        {"graph" == this.state.blob.domain &&
          <>
            {"generic" == this.state.blob.type &&
              <>
                <GraphGenericTabs
                  app={this.props.app}
                  blob={this.state.blob}
                  revision={this.state.revision}
                  match={this.props.match}
                  history={this.props.history}
                  section={tabSection}
                  forcedRevision={this.state.forcedRevision}
                />
              </>
            }
            {"dnn" == this.state.blob.type &&
              <>
                <GraphDnnTabs
                  app={this.props.app}
                  blob={this.state.blob}
                  revision={this.state.revision}
                  match={this.props.match}
                  history={this.props.history}
                  section={tabSection}
                  forcedRevision={this.state.forcedRevision}
                />
              </>
            }
          </>
        }
        {"dataset" == this.state.blob.domain &&
          <>
            {"nlp" == this.state.blob.type &&
              <>
                <DatasetNlpTabs
                  app={this.props.app}
                  blob={this.state.blob}
                  revision={this.state.revision}
                  match={this.props.match}
                  history={this.props.history}
                  section={tabSection}
                  forcedRevision={this.state.forcedRevision}
                />
              </>
            }
          </>
        }

        {this.state.revision &&
          <>
            {"graph" == this.state.blob.domain &&
              <>
                {"generic" == this.state.blob.type &&
                  <>
                    <div className={this.matchGenericSection(tabSection, "default") ? "" : "hidden"}>
                      <GraphGenericShell
                        app={this.props.app}
                        blob={this.state.blob}
                        revision={this.state.revision}
                        section={section}
                        location={this.props.location}
                      />
                    </div>
                    <div className={this.matchGenericSection(tabSection, "publish") ? "" : "hidden"}>
                      <GraphGenericPublish
                        app={this.props.app}
                        blob={this.state.blob}
                        revision={this.state.revision}
                        section={section}
                        sectionId={sectionId}
                        match={this.props.match}
                      />
                    </div>
                  </>
                }
                {"dnn" == this.state.blob.type &&
                  <>
                    <div className={this.matchGenericSection(tabSection, "default") ? "" : "hidden"}>
                      <GraphDnnShell
                        app={this.props.app}
                        org={this.state.org}
                        blob={this.state.blob}
                        revision={this.state.revision}
                        section={section}
                        location={this.props.location}
                      />
                    </div>
                  </>
                }
              </>
            }

            {"dataset" == this.state.blob.domain &&
              <>
                {"nlp" == this.state.blob.type &&
                  <>
                    <div className={this.matchGenericSection(tabSection, "default") ? "" : "hidden"}>
                      <DatasetNlpShell
                        app={this.props.app}
                        blob={this.state.blob}
                        revision={this.state.revision}
                        section={section}
                        location={this.props.location}
                      />
                    </div>
                  </>
                }
              </>
            }
          </>
        }
        {this.matchGenericSection(tabSection, "default") && !this.state.revision &&
          <Container className="blob-header-row">
            Loading revision...
          </Container>
        }

        <div className={this.matchGenericSection(tabSection, "revisions") ? "" : "hidden"}>
          <BlobRevisions
            app={this.props.app}
            blob={this.state.blob}
            revision={this.state.revision}
            match={this.props.match}
            blobInterface={this.state}
          />
        </div>

        <div className={this.matchGenericSection(tabSection, "settings") ? "" : "hidden"}>
          {(this.state.blob && this.props.app.api.isBlobSettingsAccessible(this.state.blob)) &&
            <BlobSettings
              app={this.props.app}
              org={this.state.org}
              blob={this.state.blob}
              revision={this.state.revision}
              match={this.props.match}
              history={this.props.history}
              blobInterface={this.state}
            />
          }
        </div>

        {this.state.revision &&
          <>
            <div className={this.matchGenericSection(tabSection, "default") ? "" : "hidden"}>
              <BlobMarkdown
                app={this.props.app}
                blob={this.state.blob}
                revision={this.state.revision}
                match={this.props.match}
                metadata="readme"
                title="Readme"
                createPrompt="Add Readme to describe blob contents and integration details."
              />
            </div>

            <div className={this.matchGenericSection(tabSection, "default") ? "" : "hidden"}>
              <BlobMarkdown
                app={this.props.app}
                blob={this.state.blob}
                revision={this.state.revision}
                match={this.props.match}
                metadata="license"
                title="License"
                createPrompt="Add License to inform adopters on proper adoption and attribution practices."
              />
            </div>
          </>
        }
      </>
    );
  }
}
