import React, { Component } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { GoRepo, GoGear } from "react-icons/go";
import Profile from "components/org/profile";
import Blobs from "components/org/blobs";
import Settings from "components/org/settings/settings";
import ReactGA from 'react-ga';

export default class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      org: null,
      blobs: null,
      notFound: false
    };

    this.profileInterface = {
      loadProfile: this.loadProfile
    };

    this.loadData();
  }

  loadData() {
    // Query organization profile
    this.loadProfile();

    // Query the list of blobs
    const orgId = this.props.match.params.orgId;
    this.props.app.api.orgs_id_blobs_get(orgId)
      .then(response => {
        // Remember data
        this.setState({ blobs: response.data.blobs });
      }, error => {});
  }

  loadProfile = () => {
    const orgId = this.props.match.params.orgId;
    this.props.app.api.orgsIdGet(orgId)
      .then(response => {
        // Remember data
        this.setState({ org: response.data.org });
      }, error => {
        this.setState({ notFound: true });
      });
  }

  componentDidMount() {
    ReactGA.pageview('/org');
  }

  handleTabSelect(tabSection) {
    const orgId = this.props.match.params.orgId;

    // Redirect to corresponding page
    var pagePath = `\/${orgId}`;
    if ( "blobs" != tabSection ) {
      pagePath += `\/${tabSection}`;
    }
    this.props.history.push(pagePath);
  }

  render() {
    if ( this.state.notFound ) {
      return (
        <>
          <Container className="blob-header-row header-message-row">
            Requested organization has not been found.
          </Container>
        </>
      );
    }

    if ( !this.state.org ) {
      return (
        <>
          <Container className="blob-header-row header-message-row">
            Loading organization profile...
          </Container>
        </>
      );
    }

    let section = "blobs";
    if ( 0 == this.props.match.path.indexOf("/:orgId/settings") ) {
      section = "settings";
    }

    const orgId = this.props.match.params.orgId;
    return (
      <>
        <Profile
          app={this.props.app}
          org={this.state.org}
        />
        <br/>

        <Container className="blob-header-row main-tabs">
          <Tabs defaultActiveKey="blobs" activeKey={section} onSelect={(key) => this.handleTabSelect(key)}>
            <Tab eventKey="blobs" title={<><GoRepo/> Blobs</>}></Tab>
            {this.state.org.is_self &&
              <Tab eventKey="settings" title={<><GoGear/> Settings</>}></Tab>
            }
          </Tabs>
        </Container>

        <div className={"blobs" == section ? "" : "hidden"}>
          <Blobs
            app={this.props.app}
            orgId={orgId}
            org={this.state.org}
            blobs={this.state.blobs}
          />
        </div>

        {this.state.org.is_self &&
          <div className={"settings" == section ? "" : "hidden"}>
            <Settings
              history={this.props.history}
              app={this.props.app}
              match={this.props.match}
              org={this.state.org}
              orgId={orgId}
              profileInterface={this.profileInterface}
            />
          </div>
        }

        <br/>
      </>
    )
  }
}
