import React, { Component } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import Navigation from "context/nav";

export default class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifying: false,
      deleting: false,
      deletionError: null
    };
  }

  handleDelete = () => {
    this.setState({
      modifying: true,
      deleting: true,
      deletionError: null
    });

    // Delete organization
    const orgId = this.props.org.id;
    this.props.app.api.orgsIdDelete(orgId)
      .then(response => {
        this.setState({
          modifying: false,
          deleting: false,
          deletionError: null
        });

        // Navigate to user profile page
        Navigation.push(this.props.history, Navigation.selfUserProfilePath());
      }, error => {
        // Indicate error
        this.setState({
          modifying: false,
          deleting: false,
          deletionError: error.message
        });
      });
  }

  render() {
    return (
      <>
        <h5>Delete Organization</h5>
        <hr/>
        <div>
          Organization removal cannot be reversed or undone. All blobs currently present in the organization will be
          removed along with it. Please double check that the organization you are about to remove is no longer used and
          its data is backed up.
        </div>
        {this.state.deleting &&
          <div className="content-row">
            <Alert variant="primary">
              Scheduling organization deletion...
            </Alert>
          </div>
        }
        {this.state.deletionError &&
          <div className="content-row">
            <Alert variant="warning">
              {this.state.deletionError}
            </Alert>
          </div>
        }
        {!this.state.modifying &&
          <div className="content-row">
            <Button
              type="submit"
              variant="success"
              size="sm"
              onClick={this.handleDelete}>
              Delete Organization
            </Button>
          </div>
        }
      </>
    );
  }
}
