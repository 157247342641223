export default class BaseNlpFormat {
  constructor(parent, data) {
    this.parent = parent;
    this.data = data;
  }

  getKeyField() {
    console.assert(false, "BaseNlpFormat.getKeyField not implemented");
  }

  getColumns() {
    console.assert(false, "BaseNlpFormat.getColumns not implemented");
  }

  getDefaultSorted() {
    console.assert(false, "BaseNlpFormat.getDefaultSorted not implemented");
  }

  getRowExpansionOptions() {
    return {};
  }

}