import React, { Component } from 'react';
import { Container, Form, Alert, Table, Button, Badge, Modal, Row, Col } from 'react-bootstrap';
import { GoRepo, GoLock, GoTrashcan, GoPerson } from "react-icons/go";
import Select from "react-select";

class AddMemberModal extends Component {
  constructor(props) {
    super(props);

    this.roleOptions = [
      { value: 'read', label: 'Read' },
      { value: 'write', label: 'Write' },
      { value: 'admin', label: 'Admin' },
    ];

    this.state = {
      member: {
        alias: "",
        role: this.roleOptions[0]
      }
    };

    this.form = React.createRef();
  }

  onEnter = () => {
    this.setState({
      member: {
        alias: "",
        role: this.roleOptions[0]
      }
    });
  }

  handleAddMember = () => {
    // Check form validity
    if ( !this.form.current.reportValidity() ) {
      return;
    }

    // Initiate processing
    const member = {
      user_id: this.state.member.alias,
      role: this.state.member.role.value
    };
    this.props.handleAddMember(member);

    // Close modal
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        {...this.props}
        onEnter={this.onEnter}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Add New Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={this.form}>
            <Form.Group>
              <Form.Label>
                <strong>User Alias</strong> <span className="text-muted">(required)</span>
              </Form.Label>
              <Row>
                <Col sm={3}>
                  <Form.Control
                    required
                    type="text"
                    size="sm"
                    autoFocus
                    value={this.state.member.alias}
                    onChange={(event) => {
                      let member = this.state.member;
                      member.alias = event.target.value;
                      this.setState({member: member});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Enter unique user alias (can be found on user profile page).
              </Form.Text>
            </Form.Group>
            <hr/>

            <Form.Group>
              <Form.Label>
                <strong>Role</strong> <span className="text-muted">(required)</span>
              </Form.Label>
              <Row>
                <Col sm={4}>
                  <Select
                    options={this.roleOptions}
                    value={this.state.member.role}
                    onChange={(option) => {
                      let member = this.state.member;
                      member.role = option;
                      this.setState({member: member});
                    }}
                  />
                </Col>
              </Row>
              <Form.Text muted>
                Selecting the right role is critical to make sure that blob data is properly protected.
              </Form.Text>
              <Form.Text muted>
                The following roles are supported:
              </Form.Text>
              <Form.Text muted>
                <ul>
                  <li><strong>Read</strong> Provides readonly access to blob, its metadata, and revisions.</li>
                  <li><strong>Write</strong> Allows contributing to blob metadata, and enables revision management.</li>
                  <li><strong>Admin</strong> Empowers users to administer the blob (full control).</li>
                </ul>
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={this.props.onHide}>Close</Button>
          <Button variant="success" onClick={this.handleAddMember}>Add</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class BlobMember extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleting: false,
      deletingError: null,
      memberUser: null
    };

    this.loadMemberUser();
  }

  loadMemberUser() {
    this.props.app.api.usersIdGet(this.props.member.user_id)
      .then(response => {
        this.setState({
          memberUser: response.data.user
        });
      }, error => {});
  }

  handleDelete() {
    this.setState({
      deleting: true,
      deletingError: null
    });
    this.props.accessInterface.setModifying(true);

    const memberId = this.props.member.id;
    this.props.app.api.blobMembersIdDelete(memberId)
      .then(response => {
        this.setState({deleting: false});
        this.props.accessInterface.setModifying(false);

        this.props.accessInterface.loadMembers();
      }, error => {
        this.setState({
          deleting: false,
          deletingError: error.message
        });
        this.props.accessInterface.setModifying(false);
      });
  }

  render() {
    return (
      <>
        <tr>
          <td>
            <div class="d-flex">
              <div>
                <GoPerson/>
                {this.state.memberUser &&
                  <>
                    <a href={`/p/${this.state.memberUser.alias}`}>
                      {this.state.memberUser.name &&
                        <strong>
                          &nbsp;{this.state.memberUser.name}
                        </strong>
                      }
                    </a>
                    &nbsp;
                    {this.state.memberUser.name && <>(</>}
                    {this.state.memberUser.alias}
                    {this.state.memberUser.name && <>)</>}
                  </>
                }
                &nbsp;&nbsp;
                <Badge variant="secondary" className="title-tag-badge">{this.props.member.role}</Badge>
              </div>

              <div class="ml-auto">
                {!this.props.accessInterface.modifying &&
                  <Button
                    variant="danger"
                    className="btn-icon"
                    onClick={() => this.handleDelete()}
                    ><GoTrashcan /></Button>
                }
              </div>
            </div>
          </td>
        </tr>
        {this.state.deleting &&
          <tr>
            <td style={{padding: "0px"}}>
              <div>
                <Alert variant="primary">
                  Deleting blob member...
                </Alert>
              </div>
            </td>
          </tr>
        }
        {this.state.deletingError &&
          <tr>
            <td style={{padding: "0px"}}>
              <div>
                <Alert variant="warning">
                  {this.state.deletingError}
                </Alert>
              </div>
            </td>
          </tr>
        }
      </>
    );
  }
}

export default class Access extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifying: false,
      updatingVisibility: false,
      updatingVisibilityError: null,
      creatingMember: false,
      creatingMemberError: null,
      members: null,
      setModifying: this.setModifying,
      loadMembers: this.loadMembers,
      addMemberModalShow: false,
      setAddMemberModalShow: (show) => {
        this.setState({addMemberModalShow: show});
      }
    };

    this.loadMembers();
  }

  setModifying = (modifying) => {
    this.setState({modifying: modifying});
  }

  loadMembers = () => {
    const orgId = this.props.blob.org_id;
    const blobId = this.props.blob.id;
    this.props.app.api.blobsIdMembersGet(orgId, blobId)
      .then(response => {
        this.setState({
          members: response.data.members
        });
      }, error => {});
  }

  handleUpdateVisibility = (visibility) => {
    this.setState({
      modifying: true,
      updatingVisibility: true,
      updatingVisibilityError: null
    });

    // Update blob
    const orgId = this.props.blob.org_id;
    const blobId = this.props.blob.id;
    const blob = {
      "visibility": visibility
    };
    this.props.app.api.blobsIdPatch(orgId, blobId, blob)
      .then(response => {
        this.setState({
          modifying: false,
          updatingVisibility: false,
          updatingVisibilityError: null
        });

        // Trigger blob refresh upon visibility change
        this.props.blobInterface.loadBlob();
      }, error => {
        // Indicate error
        this.setState({
          modifying: false,
          updatingVisibility: false,
          updatingVisibilityError: error.message
        });
      });
  }

  handleAddMemberInit() {
    this.setState({
      modifying: false,
      creatingMember: false,
      creatingMemberError: null,
      addMemberModalShow: true
    });
  }

  handleAddMember = (member) => {
    this.setState({
      modifying: true,
      creatingMember: true,
      creatingMemberError: null
    });

    // Create member
    const orgId = this.props.blob.org_id;
    const blobId = this.props.blob.id;
    this.props.app.api.blobsIdMembersPost(orgId, blobId, member)
      .then(response => {
        this.setState({
          modifying: false,
          creatingMember: false,
          creatingMemberError: null
        });

        // Refresh the list of members
        this.loadMembers();
      }, error => {
        // Indicate error
        this.setState({
          modifying: false,
          creatingMember: false,
          creatingMemberError: error.message
        });
      });
  }

  render() {
    return (
      <>
        <h5>Visibility</h5>
        <hr/>
        <Form>
          <Form.Group>
            <Form.Check
              type="radio"
              name="visibility"
              id="visibility-public"
              label={<><GoRepo/> Public</>}
              disabled={this.state.updating}
              checked={"public" == this.props.blob.visibility}
              onChange={(event) => {
                const visibility = event.target.checked ? "public" : "private";
                this.handleUpdateVisibility(visibility);
              }}
            />
            <Form.Text muted>
              <string>Public</string> blobs are visible to the world but only chosen users can contribute to it.
            </Form.Text>
            <Form.Check
              type="radio"
              name="visibility"
              id="visibility-private"
              label={<><GoLock/> Private</>}
              disabled={this.state.updating}
              checked={"private" == this.props.blob.visibility}
              onChange={(event) => {
                const visibility = event.target.checked ? "private" : "public";
                this.handleUpdateVisibility(visibility);
              }}
            />
            <Form.Text muted>
              <string>Private</string> blobs are invisible by default with both producers and consumers being added manually.
            </Form.Text>

            {this.state.updatingVisibility &&
              <div className="content-row">
                <Alert variant="primary">
                  Changing blob visibility...
                </Alert>
              </div>
            }
            {this.state.updatingVisibilityError &&
              <div className="content-row">
                <Alert variant="warning">
                  {this.state.updatingVisibilityError}
                </Alert>
              </div>
            }
          </Form.Group>
        </Form>

        <h5>Members</h5>
        <hr/>

        <div>
          Membership enables authenticated BlobHub users with access to blobs.
        </div>

        {this.state.members && 0 != this.state.members.length &&
          <div className="content-row">
            <Table hover bordered style={{"margin-bottom": "0px"}}>
              <tbody>
                {this.state.members.map((member, index) => {
                  return (
                    <BlobMember
                      app={this.props.app}
                      accessInterface={this.state}
                      member={member}
                    />
                  );
                })}
              </tbody>
            </Table>
          </div>
        }

        {!this.state.modifying &&
          <div className="content-row">
            <Button
              variant="success"
              size="sm"
              onClick={() => this.handleAddMemberInit()}><GoPerson/> New
            </Button>

            <span className="text-muted text-note">
              &nbsp;&nbsp;&nbsp;Give someone access to view or modify this blob
            </span>&nbsp;&nbsp;&nbsp;
          </div>
        }

        {this.state.creatingMember &&
          <div className="content-row">
            <Alert variant="primary">
              Creating blob member...
            </Alert>
          </div>
        }
        {this.state.creatingMemberError &&
          <div className="content-row">
            <Alert variant="warning">
              {this.state.creatingMemberError}
            </Alert>
          </div>
        }

        <AddMemberModal
          show={this.state.addMemberModalShow}
          onHide={() => this.state.setAddMemberModalShow(false)}
          handleAddMember={this.handleAddMember}
        />
      </>
    );
  }
}
