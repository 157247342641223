import React, { Component } from 'react';
import { Container, Button, Alert, Form, Row, Col } from 'react-bootstrap';
import Navigation from "context/nav";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifying: false,
      updating: false,
      updatingError: null,
      profile: {}
    };
    if ( this.props.user.name ) {
      this.state.profile.name = this.props.user.name;
    }
    if ( this.props.user.url ) {
      this.state.profile.url = this.props.user.url;
    }
    if ( this.props.user.description ) {
      this.state.profile.description = this.props.user.description;
    }

    this.form = React.createRef();
  }

  handleUpdate = () => {
    // Check form validity
    if ( !this.form.current.reportValidity() ) {
      return;
    }

    this.setState({
      modifying: true,
      updating: true,
      updatingError: null
    });

    // Update user profile
    this.props.app.api.usersIdPatch(this.props.user.id, this.state.profile)
      .then(response => {
        this.setState({
          modifying: false,
          updating: false,
          updatingError: null
        });

        // Refresh user profile
        this.props.profileInterface.loadProfile();
      }, error => {
        // Indicate error
        this.setState({
          modifying: false,
          updating: false,
          updatingError: error.message
        });
      });
  }

  render() {
    return (
      <>
        <h5>Profile</h5>
        <hr/>

        <Form ref={this.form}>
          <Form.Group>
            <Form.Label>
              <strong>User Alias</strong>
            </Form.Label>
            {this.props.user.alias &&
              <Row>
                <Col sm={3}>
                  {this.props.user.alias}
                </Col>
              </Row>
            }
            {!this.props.user.alias &&
              <>
                <Row>
                  <Col sm={3}>
                    <Form.Control
                      type="text" size="sm"
                      value={this.state.profile.alias}
                      onChange={(event) => {
                        let profile = this.state.profile;
                        profile.alias = event.target.value;
                        if ( !profile.alias ) { delete profile.alias; }
                        this.setState({profile: profile});
                      }}
                    />
                  </Col>
                </Row>
                <Form.Text muted>
                  Alias must confirm to the following requirements:
                  <ul>
                    <li>minimum of 6 characters;</li>
                    <li>maximum of 32 characters;</li>
                    <li>only alphanumeric characters along with <strong>-</strong>,<strong>_</strong> are allowed;</li>
                    <li>must be globally unique.</li>
                  </ul>
                </Form.Text>
              </>
            }
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <strong>Email</strong>
            </Form.Label>
            <Row>
              <Col>
                {this.props.user.email}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <strong>Name</strong>
            </Form.Label>
            <Row>
              <Col sm={6}>
                <Form.Control
                  type="text" size="sm" autoFocus
                  value={this.state.profile.name}
                  onChange={(event) => {
                    let profile = this.state.profile;
                    profile.name = event.target.value;
                    this.setState({profile: profile});
                  }}
                />
              </Col>
            </Row>
            <Form.Text muted>
              Name that suits you best.
            </Form.Text>
          </Form.Group>

         <Form.Group>
            <Form.Label>
              <strong>Url</strong>
            </Form.Label>
            <Row>
              <Col sm={6}>
                <Form.Control
                  type="text" size="sm"
                  value={this.state.profile.url}
                  onChange={(event) => {
                    let profile = this.state.profile;
                    profile.url = event.target.value;
                    this.setState({profile: profile});
                  }}
                />
              </Col>
            </Row>
            <Form.Text muted>
              Reference to your external profile.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <strong>Description</strong>
            </Form.Label>
            <Row>
              <Col sm={9}>
                <Form.Control
                  type="text" size="sm"
                  value={this.state.profile.description}
                  onChange={(event) => {
                    let profile = this.state.profile;
                    profile.description = event.target.value;
                    this.setState({profile: profile});
                  }}
                />
              </Col>
            </Row>
            <Form.Text muted>
              Brief overview of your activities and interests.
            </Form.Text>
          </Form.Group>

          {this.state.updating &&
            <div>
              <Alert variant="primary">
                Updating user profile...
              </Alert>
            </div>
          }
          {this.state.updatingError &&
            <div className="content-row-bottom">
              <Alert variant="warning">
                {this.state.updatingError}
              </Alert>
            </div>
          }
          {!this.state.modifying &&
            <div>
              <Button
                variant="success"
                size="sm"
                onClick={this.handleUpdate}>
                Update
              </Button>
            </div>
          }
        </Form>
      </>
    );
  }
}
