import React, { Component } from 'react';
import { Container, Button, Badge, Alert, Form, Row, Col } from 'react-bootstrap';
import TagsInput from 'react-tagsinput';

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifying: false,
      updating: false,
      updatingError: null,
      profile: {
        tags: this.props.org.tags || []
      }
    };
    if ( this.props.org.name ) {
      this.state.profile.name = this.props.org.name;
    }
    if ( this.props.org.url ) {
      this.state.profile.url = this.props.org.url;
    }
    if ( this.props.org.description ) {
      this.state.profile.description = this.props.org.description;
    }

    this.form = React.createRef();
  }

  handleUpdate = () => {
    // Check form validity
    if ( !this.form.current.reportValidity() ) {
      return;
    }

    this.setState({
      modifying: true,
      updating: true,
      updatingError: null
    });

    // Update org profile
    const orgId = this.props.org.id;
    this.props.app.api.orgsIdPatch(orgId, this.state.profile)
      .then(response => {
        this.setState({
          modifying: false,
          updating: false,
          updatingError: null
        });

        // Trigger org refresh upon profile update
        this.props.profileInterface.loadProfile();
      }, error => {
        // Indicate error
        this.setState({
          modifying: false,
          updating: false,
          updatingError: error.message
        });
      });
  }

  handleTagsChange = (tags) => {
    let profile = this.state.profile;
    profile.tags = tags;
    this.setState({profile: profile});
  }

  render() {
    return (
      <>
        <h5>Profile</h5>
        <hr/>

        <Form ref={this.form}>
          <Form.Group>
            <Form.Label>
              <strong>Organization Alias</strong>
            </Form.Label>
            <Row>
              <Col>
                {this.props.org.alias}
              </Col>
            </Row>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <strong>Name</strong>
            </Form.Label>
            <Row>
              <Col sm={6}>
                <Form.Control
                  type="text" size="sm" autoFocus
                  value={this.state.profile.name}
                  onChange={(event) => {
                    let profile = this.state.profile;
                    profile.name = event.target.value;
                    this.setState({profile: profile});
                  }}
                />
              </Col>
            </Row>
            <Form.Text muted>
              Name that suits this organization best.
            </Form.Text>
          </Form.Group>

         <Form.Group>
            <Form.Label>
              <strong>Url</strong>
            </Form.Label>
            <Row>
              <Col sm={6}>
                <Form.Control
                  type="text" size="sm"
                  value={this.state.profile.url}
                  onChange={(event) => {
                    let profile = this.state.profile;
                    profile.url = event.target.value;
                    this.setState({profile: profile});
                  }}
                />
              </Col>
            </Row>
            <Form.Text muted>
              Reference to organization external profile.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <strong>Description</strong>
            </Form.Label>
            <Row>
              <Col sm={9}>
                <Form.Control
                  type="text" size="sm"
                  value={this.state.profile.description}
                  onChange={(event) => {
                    let profile = this.state.profile;
                    profile.description = event.target.value;
                    this.setState({profile: profile});
                  }}
                />
              </Col>
            </Row>
            <Form.Text muted>
              Brief overview of the organization.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              <strong>Tags</strong>
            </Form.Label>
            <Row>
              <Col sm={6}>
                <TagsInput
                  value={this.state.profile.tags}
                  onChange={this.handleTagsChange}
                />
              </Col>
            </Row>
            <Form.Text muted>
              The list of tags describing this organization. <br/>
              Only alphanumeric characters along with <strong>-</strong>,<strong>_</strong> are allowed.
            </Form.Text>
          </Form.Group>

          {this.state.updating &&
            <div>
              <Alert variant="primary">
                Updating organization profile...
              </Alert>
            </div>
          }
          {this.state.updatingError &&
            <div className="content-row-bottom">
              <Alert variant="warning">
                {this.state.updatingError}
              </Alert>
            </div>
          }
          {!this.state.modifying &&
            <div>
              <Button
                variant="success"
                size="sm"
                onClick={this.handleUpdate}>
                Update
              </Button>
            </div>
          }
        </Form>
      </>
    );
  }
}
