import React from 'react';
import { Route, Redirect } from "react-router-dom";

/**
 * Route available to authenticated users (including anonymous)
 */
const AuthRoute = ({component: Component, app, ...rest}) => {
  return (
    <Route
      {...rest}
      render={
        (props) => true /* app.authenticated === true */
          ? <Component {...props} app={app} />
          : <Redirect to="/" />
      }
    />
  )
}

/**
 * Route visible to non-anonymous users
 */
const PrivateRoute = ({component: Component, app, ...rest}) => {
  return (
    <Route
      {...rest}
      render={
        (props) => ( app.authenticated === true && app.auth && app.auth.anonymous === false )
          ? <Component {...props} app={app} />
          : <Redirect to="/" />
      }
    />
  )
}

export { AuthRoute, PrivateRoute };
