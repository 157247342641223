import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

import * as d3 from 'd3'
import * as dagreD3 from 'dagre-d3'

import RevisionDetailsView from 'components/controls/RevisionDetailsView/index';

import './index.css';

export default class GraphPreview extends Component {
  constructor(props) {
    super(props);
    this.svg = null;
    this.inner = null;
    this.zoom = null;

    // This flag is used to indicate that rendering is needed but is deferred for some reason.
    // We use a number of entry points to check for it and trigger rendering.
    this.needsRender = false;
  }

  componentDidUpdate(prevProps) {
    // See if graph needs to be rendered (possibly all stars are aligned now)
    if ( this.needsRender ) {
      this.renderGraph();
    }

    // Render if new graph object is loaded 
    if (this.props.shell.graph !== prevProps.shell.graph) {
      this.renderGraph();
    }
  }

  formatShape(shape) {
    var shape_str = "";
    var separator = "[";
    for ( var dim of shape.dim ) {
      var dim_value = ( "" != dim.dim_param ? dim.dim_param : dim.dim_value );
      shape_str += separator + dim_value;
      separator = ",";
    }
    shape_str += "]"
    return shape_str;
  }

  renderGraph() {
    // See if there is anything to render
    if ( !this.props.shell.graph ) {
      return;
    }

    // Check to see if the viewport is ready. Defer rendering otherwise
    var graphElement = document.getElementById("graph");
    if ( !graphElement || 0 == graphElement.clientWidth || 0 == graphElement.clientHeight ) {
      this.needsRender = true;
      return;
    }

    // Initialize graph
    var g = new dagreD3.graphlib.Graph({compound:true}).setGraph({});
    if ( this.svg ) {
      this.svg.call(this.zoom.transform, d3.zoomIdentity);
    }

    //
    // Construct graph
    //

    var allNodeInputIndex = {};
    var allNodeOutputIndex = {};
    var initIndex = {};
    var valuesIndex = {};
    var graphOutputIndex = {};
    for (var init of this.props.shell.graph.graph.initializer) {
      initIndex[init.name] = init;
      valuesIndex[init.name] = init;
    }
    for ( var input of this.props.shell.graph.graph.input ) {
      valuesIndex[input.name] = input;
    };
    for ( var output of this.props.shell.graph.graph.output ) {
      graphOutputIndex[output.name] = output;
      valuesIndex[output.name] = output;
    };
    for ( var value of this.props.shell.graph.graph.value_info ) {
      valuesIndex[value.name] = value;
    };

    for (var node of this.props.shell.graph.graph.node) {
      var label
        = "<b>" + node.op_type + "</b><br/>";
      for (var input of node.input) {
        if ( initIndex[input] ) {
          var val = valuesIndex[input];
          label += input + " " + ( val ? this.formatShape(val.type.tensor_type.shape) : "" ) + "<br/>";
        }
      }

      g.setNode(
        node.name,
        { label: label, labelType: "html" });
      var uiVertex = g.node(node.name);
      uiVertex.node = node;
      uiVertex.selection = {
        type: "node",
        node: node
      };

      for (var input of node.input) {
        allNodeInputIndex[input] = uiVertex;
      }
      for (var output of node.output) {
        allNodeOutputIndex[output] = uiVertex;
      }
    }

    for (var node of this.props.shell.graph.graph.node) {
      for (var input of node.input) {
        if ( allNodeOutputIndex[input] ) {
          var value = valuesIndex[input];
          var shape = value ? this.formatShape(value.type.tensor_type.shape) : "";
          g.setEdge(
            allNodeOutputIndex[input].node.name,
            node.name,
            { curve: d3.curveBasis, label: shape });
        } else {
          if ( !initIndex[input] ) {
            // Add global input
            var label
              = "<b>Input</b><br/>"
              + input;
            g.setNode(
              input,
              { label: label, labelType: "html" });
            var uiVertex = g.node(input);
            uiVertex.selection = null;

            var value = valuesIndex[input];
            var shape = value ? this.formatShape(value.type.tensor_type.shape) : "";
            g.setEdge(
              input,
              node.name,
              { curve: d3.curveBasis, label: shape });
          }
        }
      }

      for (var output of node.output) {
        if ( graphOutputIndex[output] ) {
          // Add global output
          var label
            = "<b>Output</b><br/>"
            + output;
          g.setNode(
            output,
            { label: label, labelType: "html" });
          var uiVertex = g.node(output);
          uiVertex.selection = null;

          var value = valuesIndex[output];
          var shape = value ? this.formatShape(value.type.tensor_type.shape) : "";
          g.setEdge(
            node.name,
            output,
            { curve: d3.curveBasis, label: shape });
        }
      }
    }

    //
    // Configure and render graph
    //

    // Set some general styles
    g.nodes().forEach(function(v) {
      var node = g.node(v);
      node.rx = node.ry = 5;
    });

    // Find/create DOM nodes for the graph
    if ( !this.svg ) {
      this.svg = d3.select("#graph");
      this.inner = this.svg.append("g");

      // Set up zoom support
      var that = this;
      this.zoom = d3.zoom().on("zoom", function() {
        that.inner.attr("transform", d3.event.transform);
      });
      this.svg.call(this.zoom);
    }
    var svg = this.svg;
    var inner = this.inner;
    var zoom = this.zoom;

    // Run the renderer. This is what draws the final graph.
    var renderer = new dagreD3.render();
    renderer(inner, g);

    // Setup click handler
    svg.selectAll("g.node").on("click", name => {
      var uiVertex = g.node(name);
      if ( uiVertex.selection ) {
        this.props.shell.shellInterface.updateSelection(uiVertex.selection.type, uiVertex.selection.node);
      } else {
        this.props.shell.shellInterface.updateSelection("graph", null);
      }
    });

    // Center the graph
    if ( g.graph().height < 10000 ) {
      var padding = 20;
      var graphElement = document.getElementById("graph");
      var initialScale = graphElement.clientHeight / ( g.graph().height + padding );
      svg.call(zoom.transform, d3.zoomIdentity.translate(
        graphElement.clientWidth / 2 - ( ( g.graph().width + padding ) * initialScale ) / 2,
        0).scale(initialScale));
    } else {
      var topNode = g.node(g.nodes()[0]);
      g.nodes().forEach(function(v) {
        var node = g.node(v);
        if ( node.y < topNode.y ) {
          topNode = node;
        }
      });

      var graphElement = document.getElementById("graph");
      var initialScale = 0.2;
      svg.call(zoom.transform, d3.zoomIdentity.translate(
        graphElement.clientWidth / 2 - topNode.x * initialScale,
        0).scale(initialScale));
    }

    //
    this.needsRender = false;
  }

  render() {
    // See if graph needs to be rendered (possibly all stars are aligned now)
    if ( this.needsRender ) {
      this.renderGraph();
    }

    // Render the widget itself
    return (
      <>
        <Card>
          <Card.Header>
            {this.props.revision
              ? <RevisionDetailsView
                  app={this.props.app}
                  revision={this.props.revision}
                  shell={this.props.shell}
                />
              : <>Loading revision info...</>
            }
          </Card.Header>
          <Card.Body>
            <div className="graph-container">
              <svg id="graph" width="100%" height="100%"></svg>
            </div>
          </Card.Body>
        </Card>
      </>
    )
  }
}
