import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import vis from 'vis-network/dist/vis-network.min.js';
import 'vis-network/dist/vis-network.min.css';
import './graphCtrlVis.css';;

export default class GraphControl extends Component {
  constructor(props) {
    super(props);

    this.uiVertexIndex = new Map();
    this.network = null;
    this.nodes = new vis.DataSet([]);
    this.edges = new vis.DataSet([]);

    this.props.shell.graphCtrlInterface = {
      incorporateDiff: this.incorporateDiff,
      refreshAppearance: this.refreshAppearance,
      activate: this.activate,
      clear: this.clear
    };
  }

  /**
   * Control interface
   */

  activate = () => {
    this.adjustUiToRecentUpdates();
  }

  incorporateDiff = () => {
    this.incorporateDiffCore();
    this.adjustUiToRecentUpdates();
  }

  refreshAppearance = () => {
    this.refreshAppearanceCore();
    this.adjustUiToRecentUpdates();
  }

  clear = () => {
    this.edges.clear();
    this.nodes.clear();
    this.adjustUiToRecentUpdates();
  }

  /**
   * Callbacks
   */

  onSelectContent = (params) => {
    for (var nodeId of params.nodes) {
      this.applyNodeSelection(nodeId);
      return;
    }

    for (var edgeId of params.edges) {
      this.applyEdgeSelection(edgeId);
      return;
    }
  }

  applyNodeSelection(nodeId) {
    let uiVertex = this.nodes.get(nodeId);
    let vertex = uiVertex.vertex;
    this.props.shell.shellInterface.updateDetails(true, "vertex", vertex);
  }

  applyEdgeSelection(edgeId) {
    let uiEdge = this.edges.get(edgeId);
    let edge = uiEdge.edge;
    this.props.shell.shellInterface.updateDetails(true, "edge", edge);
  }

  /**
   * Rendering
   */

  createGraph() {
    // create a network
    var container = document.getElementById("explore-preview-graph");
    var data = {
      nodes: this.nodes,
      edges: this.edges
    };
    var options = {
      physics: {
        enabled: true,
        stabilization: {
          enabled: true,
          fit: true
        }
      },
      layout: {
        randomSeed: 2
      },
      nodes: {
        shape: "box",
        chosen: {
          label: false
        },
        shapeProperties: {
          borderRadius: 2
        },
        font: {
          multi: true,
          face: "Roboto",
          size: 12,
          align: "left"
        },
        margin: {
          top: 5,
          bottom: 5,
          left: 8,
          right: 8
        },
        color: {
          background: "#ffffff",
          border: "#bbbbbb",
          highlight: {
            background: "#f1f8ff",
            border: "#0366"
          }
        },
        shadow: {
          enabled: true,
          color: '#eeeeee',
          x: 2,
          y: 2,
          size: 1
        },
        opacity: 0.8
      },
      edges: {
        font: {
          size: 12,
          face: "Roboto"
        },
        shadow: {
          enabled: false
        },
        arrows: {
          to: {
            enabled: true,
            scaleFactor: 0.5
          }
        }
      }
    };
    this.network = new vis.Network(container, data, options);

    // Styling
    this.normalNodeColorStyle = {
      background: "#ffffff"
    };
    this.recentNodeColorStyle = {
      background: "#e8e8e8"
    };

    // Setup callbacks
    this.network.on("select", this.onSelectContent);
  }

  incorporateDiffCore() {
    // Clear previous node highlighting
    var notesToUpdate = [];
    for (var nodeId of this.nodes.getIds()) {
      notesToUpdate.push({
        id: nodeId,
        color: this.normalNodeColorStyle
      });
    }
    this.nodes.update(notesToUpdate);

    // Clear previous edge highlighting
    var edgesToUpdate = [];
    for (var edgeId of this.edges.getIds()) {
      edgesToUpdate.push({
        id: edgeId,
        width: 1
      });
    }
    this.edges.update(edgesToUpdate);

    if ( !this.props.shell.graph.mostRecentDiff ) {
      return;
    }

    // Process new nodes
    for (var nodeId of this.props.shell.graph.mostRecentDiff.nodes()) {
      // Skip nodes that are already present
      if ( this.nodes.get(nodeId) ) {
        // Highlight the node
        this.nodes.update([{
          id: nodeId,
          color: this.recentNodeColorStyle
        }]);
        continue;
      }

      // Add node
      const node = this.props.shell.graph.mostRecentDiff.node(nodeId);
      const LabelTemplate = this.findAppearanceTemplate("vertex", node);
      const nodeLabel = this.renderTemplate(LabelTemplate, {vertex: node});
      const uiVertex = {
        id: nodeId,
        label: nodeLabel,
        color: this.recentNodeColorStyle,
        vertex: node
      }
      this.nodes.add(uiVertex);
      this.uiVertexIndex.set(nodeId, uiVertex);
    }

    // Process new edges
    for (var edgeObj of this.props.shell.graph.mostRecentDiff.edges()) {
      // Skip edges that are already present
      const edge = this.props.shell.graph.mostRecentDiff.edge(edgeObj);
      if ( this.edges.get(edge.id) ) {
        // Highlight the edge
        this.edges.update([{
          id: edge.id,
          width: 2
        }]);
        continue;
      }

      // Add edge
      const edgeTemplate = this.findAppearanceTemplate("edge", edge)
      const edgeLabel = this.renderTemplate(edgeTemplate, {edge: edge});
      const uiEdge = {
        id: edge.id,
        from: edgeObj.v,
        to: edgeObj.w,
        width: 2,
        label: edgeLabel,
        edge: edge
      };
      this.edges.add(uiEdge);
    }
  }

  refreshAppearanceCore() {
    // Update nodes
    var notesToUpdate = [];
    for (var nodeId of this.nodes.getIds()) {
      const node = this.nodes.get(nodeId).vertex;
      const LabelTemplate = this.findAppearanceTemplate("vertex", node);
      const nodeLabel = this.renderTemplate(LabelTemplate, {vertex: node});
      notesToUpdate.push({
        id: nodeId,
        label: nodeLabel
      });
    }
    this.nodes.update(notesToUpdate);

    // Update edges
    var edgesToUpdate = [];
    for (var edgeId of this.edges.getIds()) {
      const edge = this.edges.get(edgeId).edge;
      const edgeTemplate = this.findAppearanceTemplate("edge", edge)
      const edgeLabel = this.renderTemplate(edgeTemplate, {edge: edge});
      edgesToUpdate.push({
        id: edgeId,
        label: edgeLabel
      });
    }
    this.edges.update(edgesToUpdate);
  }

  adjustUiToRecentUpdates() {
    this.forceUpdate();
    this.network.fit();
  }

  /**
   * Appearance
   */

  findAppearanceTemplate(type, object) {
    // Default templates
    if ( "vertex" == type ) {
      var template = "${vertex.class} (${vertex.id})";
    } else if ( "edge" == type ) {
      var template = "${edge.class}";
    } else {
      var template = "unknown";
    }

    // Templates from appearance store
    if ( this.props.shell.appearance ) {
      var appearanceItem = null
      if ( "vertex" == type ) {
        appearanceItem = this.props.shell.appearance.verticesIndexByClass[object["class"]];
      } else if ( "edge" == type ) {
        appearanceItem = this.props.shell.appearance.edgesIndexByClass[object["class"]];
      }
      if ( appearanceItem ) {
        template = appearanceItem["label"]["template"]
      }
    }

    return template;
  }

  renderTemplate(template, data) {
    let inject = (str, obj) => str.replace(/\${(.*?)}/g, (x, g) => {
      try {
        let value = obj;
        for ( let segment of g.split(".") ) {
          value = value[segment];
        }
        if ( undefined === value ) {
          throw "undefined";
        }
        return value;
      } catch (err) {
        return "<?>";
      }
    });
    return inject(template, data);
  }

  /**
   * Component
   */

  componentDidMount() {
    this.createGraph();
  }

  render() {
    // Presence of nodes is deemed sufficient for rendering
    const hasContent = this.nodes.length > 0;

    return (
      <>
        <div id="explore-preview-graph" className={hasContent ? "explore-preview-graph" : "hidden"} />

        <div className={hasContent ? "hidden" : ""}>
          <Alert variant="secondary">
            No content available to render. <br/>
            <br/>
            Run query to fetch some data for visualization. <br/>
            <br/>
            Start with something as simple as <br/>
            <code>SELECT FROM E LIMIT 25</code> <br/>
            to get a sense for what is available.
          </Alert>
        </div>
      </>
    );
  }
}
