import React, { Component } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { GoOrganization, GoGear } from "react-icons/go";
import ReactGA from 'react-ga';
import Profile from "components/profile/profile";
import Organizations from "components/profile/organizations";
import Settings from "components/profile/settings/settings";

export default class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      orgs: null,
      notFound: false,
    };

    this.profileInterface = {
      loadProfile: this.loadProfile
    };

    this.loadData();
  }

  loadData() {
    // Query user profile
    this.loadProfile();

    // Query the list of user's organizations
    const userId = this.props.match.params.userId;
    this.props.app.api.users_id_orgs_get(userId)
      .then(response => {
        this.setState({ orgs: response.data.organizations });
      }, error => {});
  }

  loadProfile = () => {
    const userId = this.props.match.params.userId;
    this.props.app.api.usersIdGetNoCache(userId)
      .then(response => {
        this.setState({ user: response.data.user });
      }, error => {
        this.setState({ notFound: true });
      });
  }

  componentDidMount() {
    ReactGA.pageview('/profile/user');
  }

  handleTabSelect(tabSection) {
    const userId = this.props.match.params.userId;

    // Redirect to corresponding page
    var pagePath = `\/p\/${userId}`;
    if ( "organizations" != tabSection ) {
      pagePath += `\/${tabSection}`;
    }
    this.props.history.push(pagePath);
  }

  render() {
    if ( this.state.notFound ) {
      return (
        <>
          <Container className="blob-header-row header-message-row">
            Requested user has not been found.
          </Container>
        </>
      );
    }

    if ( !this.state.user ) {
      return (
        <>
          <Container className="blob-header-row header-message-row">
            Loading user profile...
          </Container>
        </>
      );
    }

    const section = this.props.match.params.section || "organizations";

    return (
      <>
        <Profile
          app={this.props.app}
          user={this.state.user}
        />
        <br/>

        <Container className="blob-header-row main-tabs">
          <Tabs defaultActiveKey="organizations" activeKey={section} onSelect={(key) => this.handleTabSelect(key)}>
            <Tab eventKey="organizations" title={<><GoOrganization/> Organizations</>}></Tab>
            {this.state.user.is_self &&
              <Tab eventKey="settings" title={<><GoGear/> Settings</>}></Tab>
            }
          </Tabs>
        </Container>

        <div className={"organizations" == section ? "" : "hidden"}>
          <Organizations
            app={this.props.app}
            user={this.state.user}
            orgs={this.state.orgs}
          />
        </div>

        {this.state.user.is_self &&
          <div className={"settings" == section ? "" : "hidden"}>
            <Settings
              app={this.props.app}
              match={this.props.match}
              user={this.state.user}
              userId={this.props.match.params.userId}
              profileInterface={this.profileInterface}
            />
          </div>
        }

        <br/>
      </>
    )
  }
}
