import React, { Component } from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { FiUsers } from "react-icons/fi";
import "./profile.css";

export default class Page extends Component {
  render() {
    const org = this.props.org;
    return (
      <>
        <Container className="blob-header-row repo-name-row org-profile-row">
          <Row>
            <Col sm={1}>
              <FiUsers className="w-100 h-100"/>
            </Col>
            <Col>
              <h5>
                {org.name
                  ? <>{org.name}</>
                  : <>{org.alias}</>
                }
                &nbsp;&nbsp;&nbsp;
                <Badge variant="secondary" className="title-tag-badge">{org.visibility}</Badge>
                &nbsp;&nbsp;
                <Badge variant="secondary" className="title-tag-badge">{org.type}</Badge>
              </h5>
              {org.description &&
                <div className="org-profile-item-row">
                  {org.description}
                </div>
              }
              {org.url &&
                <div className="org-profile-item-row">
                  <a href={org.url}>{org.url}</a>
                </div>
              }
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
